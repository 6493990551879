.widgets_container {
    display: flex;
    flex-direction: row;
    padding-bottom: 80px;
    max-height: calc(100vh - 82px) ;
    overflow-y: auto;
    width: calc(100vw - 300px);
    /*width:calc(100vw - 310px);*/
}

.widgets_container_2 {
    display: none;
    padding-bottom: 80px;
}

.widgets_container_column {
    display: flex;
    flex-direction: column;
    width: 50%;
    /*min-height: 100vh;*/
}

@media (max-width: 1400px) {
    .widgets_container {
        flex-direction: column;
    }

    .widgets_container_column {
        width: 100%; /* Элементы занимают всю ширину в колонке */
    }
}

.widget_wrapper--full__window {
    position: fixed;
    top: 70px;
    left: 300px;

    /*position: absolute;*/
    /*top: 0px;*/
    /*left: 0px;*/
    z-index: 1000;
    width: calc(100% - 300px - 20px);
    height: calc(100% - 80px - 20px) !important;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*background-color: #75848A1F;*/

    /*box-shadow: 0px 0px 8px  #75848A1F;*/

}

.widget_wrapper-full__window--block {
    background-color: var(--background-color-white);
    border-radius: 6px;
    padding: 20px;
    margin: 10px;
    width: 100%;
    height: 100%;
}

.widget_wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    min-width: var(--widjets-width-size-542-px);
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    /*height: 200px;*/
    min-height: fit-content;

}

.add__teg--elem__header--button__add {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 25px;
    height: 25px;
}

.add__teg--elem__header--button__add img {
    width: 25px;
    height: 25px;
}

.add__teg--elem__header--button__add:hover {
    cursor: pointer;
    background-color: #75848a49;
}

.add__teg--elem__header--button-base {
    height: 36px;
    min-width: 36px;
    max-width: 36px;
    margin: 5px;
}

.add__teg--elem__header--add__teg {
    position: relative;
    right: 25px;
    width: 0px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add__teg--elem__header {
    display: flex;
    align-items: center;
    height: 48px;

}

.add__teg--elem__header button {
    height: 36px;
    width: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(14, 156, 213, 0.2) !important;
    border: 1px solid #75848a49 !important;
}

.add__teg--elem__header button:hover {
    cursor: pointer;
    background-color: #75848a49;
}

.widgets_header--name__files {
    width: 100%;
}

.widgets_header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
}

.widgets_header--header__block {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.widgets_header--open__block {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 10px 2px 2px;
}

.widgets_header--container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -17px;
    margin-right: -24px;
}

.widgets_header--open__block img {
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 6px;
    /*background-color: #149CCE;*/
}

.widgets_header--open__block img:hover {
    cursor: pointer;
    background-color: #75848a49;
}

.widgets_header > .custom_search {
    text-align: left;
    width: 100% !important;
}

.widgets_header > .custom_search > form {
    width: 100% !important;
}

.selected__tags--popup__name {
    padding: 2px 10px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    margin-left: 5px;
}

.tag-selection-item {
    display: flex;
    flex-direction: row;
    padding: 10px;
    display: flex;
    align-items: center;
}

.all__tegs--block {
    border: 1px solid #75848a49;
    border-radius: 6px;
    margin: 5px 5px 10px 5px;
    height: 200px;
}

.widjets_header_rigth {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.widjets_header_rigth--modal {
    width: 35px;
    height: 35px;
}

.widjets_header_rigth--modal__edit {
    width: 35px;
    height: 35px;
}

.widjets_header_rigth--modal__edit button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    border-radius: 6px;
    width: 35px;
    height: 35px;
}

.widjets_header_rigth--modal__edit button:hover {
    background-color: var(--hover-color-gray-blue);
    /*background-color: red;*/
    cursor: pointer;
}

/*.widjets_header_rigth--modal__edit button:focus{*/
/*    outline: 0 none;*/
/*    outline-offset: 0;*/
/*    box-shadow: 0 0 0 0.05rem #bfdbfe;*/
/*    border-color: #3b82f6;*/
/*}*/

.widjets_header_rigth--modal__edit--img {
    width: 25px;
    height: 25px;
}

.widjets_header_rigth--modal__window {
    position: relative;
    z-index: 900;
    right: 170px;
    width: 180px;
    outline: 0 none;
    outline-offset: 0;
    background-color: white;
    border: 1px solid #75848a49;
    border-radius: 6px;
}

.widjets_header_rigth--modal__window button {
    width: 100%;
    height: 25px;
    padding: 10px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: right;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.44);
}

.widjets_header_rigth--modal__window button:hover {
    background-color: var(--hover-color-gray-blue);
    cursor: pointer;
}

.widjets_header_rigth--modal__window
.widjets_header_rigth--edit .popUpItem {
    color: #75848a49 !important;
    background: none !important;
    cursor: auto !important;
}

.widjets_header_rigth--edit .p_12_popUP {
    color: #75848a49 !important;
}

.widget_wrapper--modal__edit {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.29);
    z-index: 1050;
}

.widget_wrapper--modal__edit--size {
    width: 580px;
}

.widjets_header_rigth--edit {
    background: none;
    border-radius: 6px;
    width: 35px;
    height: 35px;
    border: none;
}

.widjets_header_rigth--edit:hover {
    cursor: pointer;
    background-color: var(--hover-color-gray-blue);
}

.widjets_header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}