/* По хорошему в этом компоненте вообще не должно быть z-index,
   он должен определяться каким-то из верхних контейнеров в котором находится компонент */
/* Но у меня не получилось */
.user-popup-container {
    background-color: #ffffff;
    width: fit-content;
    height: fit-content;
    border: 1px solid #e1e7ea;
    border-radius: 6px;

    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-width: 250px;
}

.user-popup-container p {
    margin: 0px;
}

.top-panel {
    padding: 24px 24px 12px 24px;
    display: flex;
    border-bottom: 1px solid #e1e7ea;
    gap: 12px;
}

.bottom-panel {
    padding: 12px 24px 24px 24px;
    display: flex;
    flex-direction: column;
}

.email-container {
    display: flex;
    color: #75848a;
    gap: 12px;
}

.user-info-container {
    align-content: center;
    width: min-content;
}

.user-info-name {
    font-weight: 600;
    white-space: nowrap;
    display: inline-block;
}

.user-info-role {
    color: #a9b7bc;
    font-weight: 600;
    white-space: nowrap;
    display: inline-block;
}

.user-popup-overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
}
