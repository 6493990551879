.widget__task--information {
    width: 100%;
    min-height: 300px;
}

.widget__task--information__head {
    display: flex;
    min-height: 40px;
}

.widget__task--information__name {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.widget__task--information__name img{
    width: 16.67px;
    height: 16.67px;
    /*margin-top: 14px;*/
}


.widget__task--information__edit {
    display: flex;
    justify-content: right;
    align-items: start;
    width: 10%;

}

.widget__task--information__edit--block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.widget__task--information__edit--block:hover {
    cursor: pointer;
    background-color: #DFEFF4;
    border-radius: 50px;
}

.widget__task--information__edit img {
    width: 20px;
    height: 20px;
}

.widget__task--information__desk--block {
    width: 100%;
    min-height: 72px;
}

.widget__task--information__desk--block p{
    color: #75848A;
    word-break: break-word;
}

.widget__task--information__desk--block img{
    width: 400px;
}

.button__taskPriorityDisplay--chek {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 50px;
    padding-block: 0px !important;
    padding-inline: 0px !important;
    margin-top: 5px;
}

.button__taskPriorityDisplay--chek:hover {
    cursor: pointer;
}

.widget__task--information__priority--type {
    display: flex;
}

.widget__task--information__priority--type--block {
    width: 50%;
}

.widget__task--information__priority--type--block__list {
    display: flex;
    flex-wrap: wrap;
}

.widget__task--information__priority--type--block__list--margin {
    margin-right: 10px;
}

.widget__task--information__p--style {
    font-weight: 600;

}

.widget__task--information__p--style-abr {
    font-weight: 600;
    color:#75848A;
    margin-right: 5px;
    /*cursor: pointer;*/
}


.widget__task--information__tegs {

}

.widget__task--information__tegs--view {
    display: flex;
    align-items: center;
}

.widget__task--information__tegs--view__teg {
    display: flex;
    align-items: center;
}

.widget__task--information__tegs--btn {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.widget__task--information__tegs--view__teg--tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E1E7EA;
    padding: 5px;
    border-radius: 8px;
    height: 30px;
}

.widget__task--information__tegs--view__teg--tooltip p{
    color: #75848A;
}

.widget__task--information__tegs--view__teg--tooltip__open {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    background-color: #f6f8fa;
    border-radius: 6px;
    box-shadow: 5px 4px 8px 2px #75848A1F;
    max-width: 150px;
}

.widget__task--information__tegs--view__teg--tooltip__open--teg {
    margin: 10px;
}

.widget__task--information__tegs--btn__add--teg {
    display: flex;
    background-color: #DFEFF4;
    padding: 5px;
    border-radius: 8px;
    color: #149CCE;
    margin-right: 10px;
}

.widget__task--information__tegs--btn__add--teg--input input{
    all: unset;
    width: 160px;
}

.widget__task--information__tegs--btn__add--teg--btn {
    margin: 0px 0px 0px 10px;
    width: 20px;
    height: 20px;
}

.widget__task--information__tegs--btn__add--teg--btn:hover {
    background-color: #4fc44f;
    border-radius: 5px;
}

.widget__task--information__tegs--btn__add--teg--btn__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.widget__task--information__tegs--btn__add--teg--btn__img img{
    width: 20px;
    height: 20px;
}

.widget__task--information__tegs--btn__add--teg--btn button{
    all: unset;
    cursor: pointer;
}

/* Модалка тегов*/

.widget__task--information__tegs--view__task--tag .task__teg--display__popup--add__teg{
    top: 40px;
    display: flex;
    flex-direction: column;
    min-height: 384px !important;
}

.widget__task--information__tegs--view__task--tag .tegs__color--popup__button--add__save--block {
    background-color: white !important;
    border-radius: 0px 0px 6px 6px !important;
    /*border-top: white !important;*/
    /*outline: 1px solid white;*/
}