
.menuForm__component {
    overflow: scroll;
    background: var(--menu-navigations-color-white);
    box-sizing: border-box;
    max-width: 300px;
    min-width: 300px;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    border-color: #E1E7EA;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    position: sticky;
    top: 0px;
    left: 0px;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif !important;
    /*calc(var(--index) * 13);*/
}

.menuForm__component--head {

}

.menuForm__header{
    display: flex;
    height: 100%;
    max-height: var(--header-heigth-size-80px);
}


.menu__project-item {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
}

.menu__project-item img {
    margin: 5px;
    padding: 5px;
    width: 26px;
    height: 26px;
}

.menu__project-item__text {
    /*margin: 5px;*/
    padding: 5px 15px;
}

.menu__project-item__text {
    width: 100%;
}

.menuForm__component--list ul li {
    list-style: none;
    padding-inline-start: 0px !important;
    display: flex;
    align-items: center;
}

.menuForm__component--list ul {
    padding: 5px 15px 5px 40px;
}

.menuForm__component--list li {
    height: 40px;
    margin: 5px;
}

.menuForm__component--list a {
    padding: 5px;
    width: 100%;
    color: var(--text-color-form-lightgrey);
}

.menuForm__component--list__icon {
    padding: 5px 15px;
}

.menuForm__component--list--border {
    border-radius: 25px;
    /*border: 2px solid var(--border-color-bottom);*/
    background: linear-gradient(to right, var(--button-color-orange), var(--button-color-pink));
}

.menuForm__component--list .p-icon{
    position: relative;
    left: 250px;
}

.menuForm__component--list .menue_14{
    margin-left: 10px;
}

.menuForm__header{
    display: flex;
    align-items: center;
    padding-left: 10px;
}