.task-type-widget {
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    height: 24px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 7px;
}

.task-priority-widget {
    border-radius: 50px;
    height: 24px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 7px;
}

.task-status-widget {
    height: 24px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 7px;
}

.task-column-widget {
    border-radius: 50px;
    height: 24px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 7px;
}

.line-person-display-widget {
    height: 16px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0 10px 0 7px; */
}

.task-type-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.task-type-text-position {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    max-width: 235px;
}

.task-type-icon {
    margin-top: 3px;
    margin-right: 3px;
}

.line-profile-image-container {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.line-profile-image {
    border: #fff solid 2px;
    border-radius: 50px;
    height: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.nonselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.small-blue-counter {
    background-color: #149CCE;
    border-radius: 50px;
    padding: 3px;
    position: absolute;
    /*padding-top: 50%; !* Makes height equal to width *!*/
}

.opacity-40 {
    opacity: 0.4 !important;
}

.color-picker-pallet-zone {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    padding: 5px;
}

.color-picker-color-variant {
    border-radius: 50px;
    border: solid 2px white;
    width: 24px;
    height: 24px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.color-picker-color-variant.selected {
    outline: solid 2px #149CCE
}
.task-teg-widget {
    background-color: #DFEFF4;
    padding: 5px;
    border-radius: 8px;
    color: #149CCE;
}

.task-column-text {
    height: fit-content !important;
}
