.historyProj_heigth {
  height: 274px;
}

.widget_wrapper--full__window .history_project_table {
  overflow-y: scroll;
  height: calc(100% - 25px - 20px) !important;
}

.history_project_table td {
  vertical-align: top;
}

.project-history-widget .filter_container {
  top: 40px;
  /* right: 100px; */
  left: -260px;
}