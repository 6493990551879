.genInfo_body {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.genInfo_body_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-left: 10px;
}

.genInfo_body .p-progressbar{
    background: var(--background-color-body);
    height: 8px;
}
.genInfo_body .p-progressbar-label{
    display: none;
}

.genInfo_body .p-progressbar-value{
    background: var(--background-color-blue);
}

.genInfo_heigth{
    height: 197px;
}

