:root {
    --widget-color-th-text: #56666C;
    --widget-color-td-text: #12191C;
}

.ui__component--table {
    /*width: ;*/
    /*overflow: hidden;*/
}

.ui__component--table__head {
    padding: 8px 0px;
}

.ui__component--table__th {
    font-weight: 400;
    font-size: 12px;
    padding: 0px 6px;
    color: var(--widget-color-th-text);
    min-width: fit-content;
    height: 42px;
}

.ui__component--table__sort--th {
    font-weight: 400;
    font-size: 12px;
    padding: 0px 0px 0px 6px;
    color: var(--widget-color-th-text);
    min-width: fit-content;
    height: 42px;
}

.ui__component--table__sort--th__block {
    display: flex;
    align-items: center;
    padding-block: 0px;
    padding-inline: 0px;
    border: none;
    background-color: unset;
    font-weight: 400;
    font-size: 12px;
    color: var(--widget-color-th-text);
    min-width: fit-content;
    height: 42px;
}

.ui__component--table__sort--th img {
    width: 20px;
    height: 20px;
}

.ui__component--table__sort--th:hover{
    cursor: pointer;
}

.ui__component--table__sort--th button:hover{
    cursor: pointer;
}

.ui__component--table__td {
    font-weight: 400;
    font-size: 14px;
    padding: 0px 6px;
    color: var(--widget-color-td-text);
    min-width: fit-content;
}