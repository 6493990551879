.modal__confirm--block__head {
    color: var(--ui-color-absolute-position-head);
}

.modal__confirm--block__body {
    height: max-content;
    color: var(--ui-color-absolute-position-body);
}

.modal__confirm--block {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 555px;
    min-height: 200px;
    height: fit-content;
    border-radius: 6px;
}

.modal__confirm--block__buttons {
    display: flex;
    align-items: end;
    justify-content: right;
    /*padding-top: 30%;*/
}