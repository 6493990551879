.monitor-table-link:hover {
  text-decoration: underline !important;
}

.monitor-table-guards {
  min-width: 60vw;
}

.monitor-table-task-code {
  color: var(--text-color-names-gray);
}