.select__user--component__find {
    border: 1px solid #ebeef3;
    height: 35px;
    padding: 8px;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #6b7280;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.select__user--component__person {
    width: 100%;
}

.select__user--component__check--mark {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select__user--component__modal {
    width: 100%;
    height: 0px;
}

.select__user--component__menu--list {
    padding-top: 1em;
    padding-bottom: 1em;
    max-height: 160px;
    overflow-y: auto;
}

.select__user--component__menu {
    width: 100%;
    /*top: -15px;*/
    position: relative;
    background-color: #fff;
    box-shadow: 5px 4px 8px 0px rgba(85, 95, 100, 0.12);
    border-radius: 6px;
    border: 1px solid #ebeef3;
    z-index: 4000;
}

.select__user--component__menu--item:hover {
    background-color: #ebeef3;
    cursor: pointer;
}

.select__user--component__menu--item {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #6b7280;
}

.select__user--component__menu--item__none {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #6b7280;
}

.select__user--component__menu--search {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #ebeef3;
    margin: 1px;
}

.select__user--component__menu--search:focus {
    outline: 1px solid rgba(20, 156, 206, 0.21);
}


.select__user--component__menu--search input {
    width: 100%;
    height: 35px;
    border: none;
    padding-left: 14px;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
}

.select__user--component__menu--search input:focus {
    width: 100%;
    height: 35px;
    border: none;
    outline: none;
}

.select__user--component__menu--search button {
    width: 35px;
    height: 35px;
    border: none;
    background: none;
}

.select__user--component__menu--search button:hover {
    background-color: rgba(20, 156, 206, 0.21);
    border-radius: 6px;
    cursor: pointer;
}

.select__user--component__menu--click {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /*background-color: #2c5494;*/
}

.select__user--component__person--number {
    background: #149cce;
    /*min-width: 30px;*/
    /*max-width: 30px;*/
    padding: 3px 6px;
    border-radius: 3px;
    color: #fff;
}