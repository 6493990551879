.color__picker--block {
    margin-top: 10px;
    border-top: 1px solid #75848a63;
}

.color__picker--block__input {
    display: flex;
    margin-top: 10px;
}

.color__picker--block__input input {
    font-size: 18px;
    text-align: center;
    margin-right: 10px;
}

/* color: #149CCE; */

.color__picker--block__input button {
    min-width: 100px;
    height: 30px;
    background-color: #149CCE;
    color: white;
}

.color__picker--block__name {
    margin-top: 10px;
}