.input_lists{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.input_lists .card{
    width:47%;
    margin-right: 3px;
}

.input_lists .p-dropdown{
    width:100%;
}

.p-dropdown-panel .p-dropdown-header{
    padding: 2px;
    
}

.p-dropdown-filter-container .p-dropdown-filter{
    height: 35px;
}

.p-dropdown {
    border: 1px solid #ebeef3;
    height: 35px;
    flex-direction: row;
    align-items: center;
}

.p-dropdown .p-inputtext{
    font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding:  0px;
    height: 35px;
    padding-left: 10px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    font-size: 14px;
}

.position{
    position: relative;
    left: 5px;
}
