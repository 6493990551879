.project__add--person__role--modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #75848a49;
    top: 0px;
    left: 0px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project__add--person__role--block {
    background-color: white;
    width: 580px;
    max-height: 90%;
    padding: 20px;
    border-radius: 6px;
}

.project__add--person__role--block__edit--role {
    width: 100%;
    max-height: 90%;
}

.project__add--person__role--block__header {
    display: flex;
    margin-bottom: 20px;
}

.project__add--person__role--block__header--name {
    font-size: 22px;
    font-weight: 600;
    text-wrap: nowrap;
}

.project__add--person__role--block__header--exit {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
}

.project__add--person__role--block__header--exit button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.project__add--person__role--block__header--exit img {
    width: 18px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project__add--person__role--block__header--exit button:hover {
    background-color: rgba(14, 156, 213, 0.36);
    cursor: pointer;
}

.project__add--person__role--block__settings {
    display: flex;
    flex-direction: column;
}

.project__add--person__role--block__settings--name__item {
    font-size: 16px;
    font-weight: 500;
    color: rgba(138, 138, 138, 0.86);
}

.project__add--person__role--block__settings--item {
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.add__user--dropdown__new {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid rgba(138, 138, 138, 0.16);
}

.project__add--person__role--block__settings--item .p-dropdown {
    width: 100%;
}

.project__add--person__role--block__buttons--exit button{
    background-color: rgba(14, 156, 213, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    color: #149CCE;
    font-weight: 600;
}

.project__add--person__role--block__buttons--add button{
    background-color: #149CCE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: 600;
}

.project__add--person__role--block__buttons--exit button:hover {
    background-color: rgba(14, 156, 213, 0.13);
    cursor: pointer;
}

.project__add--person__role--block__buttons--add button:hover {
    background-color: rgba(20, 156, 206, 0.67);
    cursor: pointer;
}

.project__add--person__role--block__buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.project__add--person__role--block__buttons--errors {
    font-size: 14px;
    font-wight: 500;
    height: 18px;
    /*padding: 5px;*/
    /*margin: 5px;*/
    color: #B84E40;
}