.task__create--edit--form__tab--menu {
    box-shadow: inset 0 -2px 0 #e0e7ea;
    margin: 0px 20px 10px 20px;
}

.task__create--edit--form__tab--menu--button {
    background-color: rgba(255, 255, 255, 0);
    font-weight: 600;
    font-size: 16px;
    border: none;
    color: #e0e7ea;
    width: auto;
    height: 35px;
}

.task__create--edit__form--tab__panel--textarea {
    font-weight: 400;
    font-size: 14px;
    color: rgba(138, 138, 138, 0.671);
}

.task__create--edit--form__tab--menu--button:hover {
    background-color: rgba(117, 132, 138, 0.12);
    color: #149cce;
    border-bottom: 2px solid #149cce;
    cursor: pointer;
}

.task__create--edit--form__tab--menu--button__selected {
    color: #149cce;
    border-bottom: 2px solid #149cce;
}

.task__create--edit__form--tab__panel--block {
    overflow-y: auto;
    padding: 0px 20px
}

.tag-display-selection-zone {
    display: flex;
    flex-direction: row;
}

.tag-holding-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.task__create--edit__form {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    /*align-items: left?;*/
    height: 90vh;
}

.task__create--edit__form--header {
    display: flex;
    align-items: center;
    padding: 20px 20px 10px 20px;
}

.task__create--edit__form--head {
    font-weight: 700;
    font-size: 24px;
    width: 100%;

}

.task__create--edit__form--button button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.task__create--edit__form--button img {
    width: 18px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task__create--edit__form--button button:hover {
    background-color: rgba(14, 156, 213, 0.36);
    cursor: pointer;
}

.p-unselectable-text {
    margin-right: 15px;
}

.p-tabview-selected span {
    color: #149CCE !important;
    font-weight: 600 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #149CCE;
}

.p-unselectable-text span {
    color: #d0d6d9;
    border-color: #e0e7ea;
    font-weight: 500;
}

.task__create--edit__form .p-highlight {
    display: flex;
    justify-content: left;
    margin-right: 15px;
}

.p-tabview-nav-link {
    padding: 15px 0px 15px 0px;
}

.p-tabview-panels {
    padding: 0px;
    margin-top: 15px;
}

.task__create--edit__form--tab__panel--name__task {
    margin-top: 8px;
    margin-bottom: 5px;
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.task__create--edit__form--tab__panel--about__task {
    margin-top: 8px;
    margin-bottom: 5px;
    font-weight: 400;
    color: black;
    font-family: Inter;
    font-size: 15px;
    /*font-weight: 400;*/
    line-height: 24px;
    font-family: Inter;
}

.task__create--edit__form--tab__panel--textarea__lenght {
    position: relative;
    bottom: 30px;
    height: 0px;
    padding-right: 5px;
    display: flex;
    justify-content: right;
    color: rgba(117, 132, 138, 0.49);
    /*border: 1px solid red;*/
}

.task__create--edit__form--tab__panel--textarea {
    border: 2px solid #e0e7ea;
    border-radius: 6px;
    color: #17141d;
    font-family: Montserrat Variable, sans-serif;
    font-size: 14px;
    height: 100px;
    padding: 10px;
    resize: none;
    width: 100%;
}

.task__create--edit__form--tab__panel--textarea:focus {
    outline: none !important;
    border: 2px solid #149cce;
}

.task__create--edit__form--tab__panel--textarea::placeholder {
    color: #d0d6d9;
    height: 18px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.task__create--edit__form--tab__panel--textarea__about {
    border: 2px solid #e0e7ea;
    border-radius: 6px;
    font-family: Montserrat Variable, sans-serif;
    font-size: 14px;
    max-height: 200px;
    padding: 10px;
    resize: none;
    width: 100%;
}

.task__create--edit__form--tab__panel--textarea__about:focus {
    outline: none !important;
    border: 2px solid #149cce;
}

.task__create--edit__form--tab__panel--textarea__about::placeholder {
    color: #d0d6d9;
    height: 18px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

}

.task__create--edit__form .p-tabview .p-tabview-panels {
}

.task__create--edit__form--tab__panel textarea {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.task__create--edit__form--tab__panel__err {
    color: #ef4444 !important;
    border-bottom: 2px solid #ef4444 !important;
}

.task__create--edit__form--tab__panel--type__task--all {
    padding: 0px 20px 0px 20px
}

.task__create--edit__form--tab__panel--type__task {
    margin-top: 8px;
    margin-bottom: 5px;
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.task__create--edit__form--tab__panel--type__block__response {
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 10px;
    max-height: 40px;
}

.task__create--edit__form--tab__panel--type__block__response {
    max-height: 48px;
    overflow: hidden;
    display: flex;
    justify-content: left;
    /*align-items: c;*/
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 10px;
}

.task__create--edit__form--tab__panel--type__block {
    /* border-radius: 6px; */
    /* border: 2px solid #e0e7ea; */
    max-height: 200px;
    overflow: auto;
    min-height: 200px;
    display: flex;
    justify-content: left;
    /*align-items: c;*/
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 10px;
}

.task__create--edit__form--tab__panel--type__block--delete {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #75848A;
    border-radius: 30px;
    margin: 2px;
    /* background-color: #75848a49; */
}

.task__create--edit__form--tab__panel--type__block--delete:hover {
    cursor: pointer;
    background-color: #75848a49;
}

.task__create--edit__form--tab__panel--type__block--group {
    display: flex;
    align-items: center;
}

.task__create--edit__form--tab__panel--type__block .button_add {
    zoom: 0.7;
}

.task__create--edit__form--tab__panel--block__person {
    display: flex;
    align-items: center;
    height: 20px;
}

.task__create--edit__form--tab__panel--type__block--non__border button,
.task__create--edit__form--tab__panel--type__block--border button {
    background-color: #ffffff00;
    border: none;

}

.task__create--edit__form--tab__panel--type__block--border {
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
}

.task__create--edit__form--tab__panel--type__block--border__board {
    position: absolute !important;
    width: 460px;
}

.task__create--edit__form--tab__panel--tab__board {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
}

.task__create--edit__form--tab__panel--tab__board:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.4);
}

.task__create--edit__form--tab__panel--type__block--border.conteiner_pointer {
    cursor: pointer;
    display: flex;
    align-items: center;

}
.task__create--edit__form--tab__panel--type__block--border.conteiner_pointer.div_with_error {
    border-color: red !important;
    border-width: 1px !important;
    animation: inputErrorAnimation 1s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.task__create--edit__form--tab__panel--type__block--border.conteiner_pointer img{
    flex-direction: row-reverse;
}

.task__create--edit__form--tab__panel--type__block--border.overView {
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 5px;
    position: relative;
    background: white !important;
    z-index: 2000;
}

.task__create--edit__form--tab__panel--button {
    display: flex;
    justify-content: right;
    margin: 20px 20px 20px 0px;
}

.task__create--edit__form--tab__panel--button button {
    border: none;
    background-color: rgba(255, 255, 255, 0)
}

.task__create--edit__form--tab__panel--button__close {
    background-color: rgba(14, 156, 213, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.task__create--edit__form--tab__panel--button__add {
    background-color: #149CCE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
}

.task__create--edit__form--tab__panel--button__close button {
    color: #149CCE;
    font-weight: 600;
    cursor: pointer;
}

.task__create--edit__form--tab__panel--button__add button {
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.calendar--block__flex {
    display: flex;
}

.calendar--block__flex--inp {
    width: 180px;
    margin-right: 10px;
    margin-top: 20px;
}

.time-variants-container__chose {
    width: 40px;
    height: 40px;
    background-color: #149cce34;
    color: #149CCE;
    border: none;
    border-radius: 6px;
}

.time-variants-container__chose:hover {
    cursor: pointer;
}

.time-variants-container__unchose {
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid rgba(190, 190, 190, 0.2);
    border-radius: 6px;
}

.time-variants-container__unchose:hover {
    background-color: #149cce34;
    color: #149CCE;
    border: none;
    cursor: pointer;
}

.tag-display-field {
    position: relative;
    display: flex;
    background-color: #EDF6F9;
    color: #149CCE;
    border-radius: 6px;
    padding: 5px;
    max-height: var(--button-height-size-36-px);
    white-space: nowrap;
}

.time-variants-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.time-variant-button {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 6px;
    cursor: pointer;
}

.time-variant-button:hover {
    background-color: gray;
}

.custom-time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-time-container--input input {
    width: 60px;
    border-radius: 6px;
    border: 2px solid #7e7e7e34;
}

.custom-time-container--pass {
    width: 20px;
    height: 40px;
    text-align: center;
}

.custom-time-input {
    height: 36px;
    width: 36px;
}

.custom-time-input:focus {
    outline: none !important;
}

.custom-time-container--time__text {
    text-align: center;
}

.person-selector-items_new {
    display: flex;
    flex-direction: column;
    height: 140px;
    overflow-y: scroll;
}

.person-selector-items_new-name {
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
}

.person-selection-item {
    display: flex;
    height: 30px;
}

.person-selection-item:hover {
    color: var(--text-color-widgets-blue);
    cursor: pointer;
}

#create-task-add-tag-popup {
    position: absolute;
    visibility: hidden;
    transform: translate(0, 36px);
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

#create-task-add-tag-popup.active {
    visibility: visible;
}

.person-selector-container {
    position: absolute;
    background-color: white;
    z-index: 1003;
    bottom: 60px;
    left: 0px;
    border-radius: 5px;
    padding: 5px;
    width: 400px;
}

.task_create_inner_conteiner {
    overflow: hidden;
    /* min-height: 680px; */
    max-height: 680px;
    /* max-height: 680px; */
    position: relative;
    overflow-y: scroll;
}

.task-completion-percentage-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.task-completion-percentage-slider .p-slider-range {
    background-color: var(--text-color-widgets-blue);
    border-radius: 3px;
}

.task-completion-percentage-slider .p-slider-handle {
    border-color: var(--text-color-widgets-blue);
}

.task-completion-percentage-slider .p-slider-handle:hover {
    background-color: var(--text-color-widgets-blue);
}

@media (max-height: 800px) {
    .task_create_inner_conteiner {
        max-height: 470px;
    }
}