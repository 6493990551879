.filter_container {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 296px;
    height: fit-content;
    border-radius: 6px;
    border: 1px solid #E1E7EA;
    box-shadow: 6px 1px 10px 0px #75848A1F;
    position: absolute;
    left: -200px;
    top: 30px;
    z-index: 100;
}

.filter_container .filter_items {
    display: block;
    height: fit-content;
    width: fit-content;
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: 300px;
    top: 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    border: 1px solid #E1E7EA;
    box-shadow: 6px 1px 10px 0px #75848A1F;
    /*padding-left: 10px !important;*/
    /*padding: 10px;*/
}

.filter_container .filter_users{
    padding-left: 0px !important;
    padding: 0px ;
    max-height: fit-content;
}

.filter_container .p-calendar {
    width: 162px;
}

.filter_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-right: 10px;
    cursor: pointer;

}

.filter_container .filter_items .filter_item {
    margin-left: 10px;
}

.filter_container .p-listbox {
    width: 162px;
}

.filter_item:hover {
    color: var(--background-color-blue);
    background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}

.filter_item_left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filter_item_inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px;
    overflow:hidden;
}

.filterBtnApply {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.filterBtnApply .p-button {
    width: 100%;
    background-color: var(--background-color-blue);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 140px;
    font-weight: 400;
    font-size: 14px;
}

.filter_item_inner .custom-checkbox>span::before {
    width: 16px;
    height: 16px;
}

.filter_container .p-listbox .p-listbox-header {
    padding: 0px;
}

.filter_container .p-listbox .p-listbox-list {
    padding: 0px;
}

.filter_container .p-listbox .p-listbox-list .p-listbox-item {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
}

.filter_container .p-inputtext {
    padding: 3px;
    font-size: 12px;
    height: 28px;
}

.filter_container .p-listbox .p-listbox-header .p-listbox-filter-icon {
    color: var(--background-color-blue);
}

.filter_container .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--background-color-blue);
    /* background: var(--background-color-white); */
    background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}

.filter_container .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
    color: var(--background-color-blue);
    background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: var(--background-color-blue);
    background: var(--background-color-white);
}

.filter_container .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: var(--background-color-blue);
    background: var(--background-color-white);
}

.p-listbox {
    background: #ffffff;
    color: #4b5563;
    border: none;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    outline-color: transparent;
  }
  .p-listbox .p-listbox-header {
    padding: 0.75rem 1.25rem;
    border-bottom: none;
    color: #374151;
    background: #f9fafb;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

