.thin {
    /* Montserrat Thin = 100 */
    font-weight: 100;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}

.extralight {
    /* Montserrat Extra Light = 200 */
    font-weight: 200;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.light {
    /* Montserrat Light = 300 */
    font-weight: 300;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.regular {
    /* Montserrat Regular = 400 */
    font-weight: 400;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.medium {
    /* Montserrat Medium = 500 */
    font-weight: 500;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.semibold {
    /* Montserrat Semi-bold = 600 */
    font-weight: 600;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.bold {
    /* Montserrat Bold = 700 */
    font-weight: 700;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.extrabold {
    /* Montserrat Extra Bold = 800 */
    font-weight: 800;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}


.black {
    /* Montserrat Black = 900 */
    font-weight: 900;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
}

.input_reser {
    border: none;
    /* Убирает границу */
    appearance: none;
    /* Убирает стили браузера для элементов ввода */
    outline: none;
    /* Убирает контур фокуса */
    margin: 0;
    /* Убирает внешние отступы */
    padding: 0;
    /* Убирает внутренние отступы */
    background: none !important;
    /* Убирает фон */
    font: inherit;
    /* Наследует шрифт */
    line-height: normal;
    /* Устанавливает нормальную высоту строки */
}

.input__block-authorization {
    border: none;
    /* Убирает границу */
    appearance: none;
    /* Убирает стили браузера для элементов ввода */
    outline: none;
    /* Убирает контур фокуса */
    margin: 0;
    /* Убирает внешние отступы */
    padding: 0;
    /* Убирает внутренние отступы */
    background: none !important;
    /* Убирает фон */
    font: inherit;
    /* Наследует шрифт */
    line-height: normal;
    /* Устанавливает нормальную высоту строки */

    width: calc(var(--index) * 12);
    height: calc(var(--index) * 1.5);
    margin: 10px;
    color: var(--text-color-form-black);
    /*border-bottom: 2px solid var(--border-color-bottom);*/
}

.input__block-authorization::-webkit-input-placeholder {
    color: var(--placeholder-color-form-grey);
}

.input_widjets {
    width: 98%;
    height: 40px;
    padding: 0px 0px 0px 8px;
    border-radius: 6px;
    border: 1px solid #dddddd;
    margin-left: 5px;
    
}

.input_widjets:focus{
    box-shadow: 0px 2px 6px 2px #31313126;
}

.input_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}