.main__page {
   display: grid;
   grid-auto-flow: column;
   /*flex-direction: row;*/
}

.main__page-body {
   width: 100%;
   height: 100%;
   /*background-color: #0b76ef;*/
}

.external__page-body {
   width: 100%;
   height: 100%;
}

.main__page-body-content {
   display: flex;
   flex-direction: row;
}

.main__page-body-content1{
   width: 50%;
}

.main__page-header .inner-element {
   max-width: 100%;
}

.main__page-uper__menu {
    display: flex;
}