.task__person--block {
    height: 100%;
    height: fit-content;
    min-height: 100%;
}

.task__person--block__executor {
    display: flex;
    flex-direction: column;
}

.task__person--block--name {
    font-weight: 700;
}

.task__person--block--img {
    width: 30px;
    height: 30px;
}

.task__person--block--img img {
    width: 30px;
    height: 30px;
}

.task__person--block--person {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px;
}

.task__person--block--name__person {
    margin-left: 10px;
    font-weight: 500;
}

.task__person--block--name__person--group {
    margin-right: 5px;
    font-weight: 500;
    max-width: 30px;
    max-height: 30px;
}

.task__person--block--name__person--in {
    position: relative;
    bottom: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
}

.task__person--block--line__group {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    cursor: pointer;
}

.task__person--block__subtasks--name {
    margin: 10px 0px;
}

.task__person--block--more{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #F8FBFB;
    border: 1px solid #E1E7EA;
    border-radius: 30px;
    margin-right: 5px;
}