.sort-direction-changing-header {
    cursor: pointer;
}

.sort-direction-changing-header-flex {
    display: flex;
    flex-direction: row;
}

.sort-direction-changing-header:hover {
    background-color: var(--hover-color-gray-blue);
    border-radius: 6px;
}

.sort-direction-changing-header.desc>img {
    /*background-color: red;*/
    transform: rotate(180deg);
    /* Standard syntax */
}

.no-highlight {
    -webkit-user-select: none;
    /* Chrome, Safari, Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
}

.ellipses {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    z-index: 0;
}

.name_colum--full__window {
    display: block;
    width: 450px;
}

.name_colum {
    display: block;
    width: 150px;
}

.header_tasks{
    display: flex;
    flex-direction: row;
}