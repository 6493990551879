.files-widget-items-container__block {
    display: flex;
    height: 50px;
}

.files-widget-items-container__scroll {
    max-height: 250px;
    min-height: 250px;
    overflow-y: auto;
}

.files-widget-items-container__img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.files-widget-items-container__block img{
    width: 30px;
    height: 30px;
}

.files-widget-file_item_name--text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: default;
}

.files-widget-file_item_name--text__curent {
    font-size: 14px;
    font-weight: 500;
}

.task__component--right__menu--files {
    display: flex;
    height: 30px;
    font-weight: 700;
}

.task__component--right__menu--files__name {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.task__component--right__menu--files__btn {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 8px;
}

.task__component--right__menu--files__size {
    min-width: 30px;
    max-width: 30px;
    height: 50px;
    display: flex;
    align-items: center;
}

.task__component--right__menu--files__size img {
    min-width: 20px;
    max-width: 20px;
    height: 20px;
}