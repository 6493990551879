.system__settings {
    background-color: #fff;
    margin: 10px 30px 10px 10px;
    padding: 20px;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
    height: auto;
}

.system__settings--body {
    overflow-y: auto;
    height: calc(100vh - 190px);
}

.system__settings--header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    z-index: 900;
}

.system__settings--header__tabs {
    box-shadow: inset 0 -2px 0 #C5CFD3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: -2px;
    border-bottom: -2px solid #C5CFD3;
}

.system__settings--header__button {
    background-color: rgba(255, 0, 0, 0);
    border: none;
    border-bottom: 2px solid transparent;
    padding: 6px 0px 12px 0px;
    width: fit-content;

    color: #C5CFD3;
    font-size: 16px;
    font-weight: 600;
}

.system__settings--header__button:hover {
    cursor: pointer;
    color: #149cce;
    /* background-color: rgba(197, 207, 211, 0.55); */
    border-bottom: 2px solid #149cce;
}

.system__settings--header__button__selected {
    color: #149cce;
    border-bottom: 2px solid #149cce;
}