.system--users__component {
    width: 100%;
    margin-top: 20px;
}

.system--users__component--table {
    width: 90%;
    border-spacing: 0px;
    border-collapse: collapse;
    /* border: 1px solid green; */
}

.system--users__component--table__tr {
    /* border: 1px solid orange; */
    /* display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center; */
    width: 100%
}

.system--users__component--table__tr th {
    height: 48px;
    font-weight: 500;
    color: #75848A;
    text-align: left;
    /*margin: 5px;*/
    /* border: 1px solid red; */
    /* display: flex;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: left; */
}

.system--users__component--table__content--tr td {
    /* border: 1px solid orange; */
}

.system--users__component--table__content--td__chekbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 52px;
}

.system--users__component--table__content--td__chekbox input {
    width: 20px;
    height: 20px;
}

.system--users__component--table__content--td__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid rgba(117, 132, 138, 0.38);
    overflow: hidden;
}

.system--users__component--table__content--td__img img{
    width: 30px;
    height: 30px;
}

.system--users__component--table__content--td__block--img {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 52px;
}

.system--users__component--table__content--td__block--name__email {
    margin-left: 10px;
}

.system--users__component--table__content--td__block--name {
    font-weight: 600;
}

.system--users__component--table__content--td__block--email {
    margin-top: 5px;
    color: rgba(117, 132, 138, 0.726);
}

.system--users__component--table__content--td__role {
    display: flex;
    align-items: center;
    justify-content: left;
}

.system--users__component--table__content--td__role--name {
    font-weight: 600;
}

.system--users__component--table__content--td__date {
    font-weight: 600;
    padding: 0px 0px 0px 5px;
}

.system--users__component--table__content--td_status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 50px;
    font-weight: 600;
}

.system--users__component--table__content--th__status {
    padding: 5px 0px 5px 0px;
    padding-left: 5px;
    border: 1px solid rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    min-width: 200px;
    width: 100%;
    height: 40px;
}

.system--users__component--table__content--th__status:hover {
    cursor: pointer;
}

.system--users__component--table__content--th__status:hover>.system--users__component--table__content--th__status--header {
    color: #149cce;
}

.system--users__component--table__content--th__datentry {
    padding: 5px 0px 5px 0px;
    padding-left: 5px;
    border: 1px solid rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    width: 250px;
    height: 40px;
}

.system--users__component--table__content--th__datentry:hover {
    cursor: pointer;
    color: #149cce;
}

.system--users__component--table__content--th__datentry--img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.system--users__component--table__content--th__datentry--img img{
    width: 20px;
    height: 20px;
}

.system--users__component--table__content--td__role--choise {
    position: relative;
    width: 0px;
    height: 0px;
}

.system--users__component--table__content--td__status--choise:hover {
    cursor: auto;
}

.system--users__component--table__content--td__status--choise {
    position: relative;
    width: 0px;
    height: 0px;
    right: 80px;
    top: 10px;
}

.system--users__component--widget__Edit--block__status--absolut {
    /*width: 200px;*/
    background-color: #fff;
    min-width: 220px;
    padding: 10px;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
    /*min-height: 300px;*/


    overflow: auto;
}

.system--users__component--widget__Edit--block__role--absolut {
    /*width: 200px;*/
    background-color: #fff;
    min-width: 220px;
    padding: 10px;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
    /*min-height: 300px;*/
    top: 10px;
    overflow: auto;
}

.system--users__component--table__content--td__role--choise__one--clean {
    padding: 5px;
    border-radius: 6px;
    width: 100%;
    color: rgba(255, 0, 0, 0.73);
    border: 1px solid rgba(255, 0, 0, 0);
}

.system--users__component--table__content--td__role--choise__one--clean:hover {
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.16);
}

.system--users__component--table__content--td__role--choise__one {
    padding: 5px;
    border-radius: 6px;
    width: 100%;
}

.system--users__component--table__content--td__role--choise__one:hover {
    cursor: pointer;
    background-color: #149cce3d;
}

.system--users__component--table__content--td__role--block {
    width: 280px;
}

.system--users__component--table__content--td__block--role {
    width: 280px;
}

.system--users__component--widget__Edit {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.system--users__component--widget__delete {
    width: 480px;
    border-radius: 6px;
    background-color: white;
    padding: 20px;
}

.system--users__component--widget__add {
    width: 480px;
    border-radius: 6px;
    background-color: white;
    padding: 20px;
}

.system--users__component--widget__add--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-wrap: nowrap;
    font-weight: 600;
    font-size: 22px;
}

.system--users__component--widget__add--header__input {
    width: 100%;
}

.system--users__component--widget__add--header__input input{
    border-radius: 6px;
    border: 2px solid #e0e7ea;
    padding: 10px;
    height: 36px;
    width: 100%;
    font-family: 'Montserrat Variable', sans-serif;
    font-size: 16px;
}

.system--users__component--widget__add--header__input input:focus {
    outline: none !important;
    border: 2px solid #149cce;
}

.system--users__component--widget__add--header__text {
    font-weight: 500;
    font-size: 16px;
    color: black;
    margin: 10px 0px 5px 0px;
}

.system--users__component--widget__add--header__role {
    disply: flex;
    align-items: center;
}

.system--users__component--widget__add--header__block {
    margin-top: 15px;
}

.system--users__component--widget__Edit--block {
    width: 480px;
    border-radius: 6px;
    background-color: white;
    padding: 20px;
}

.system--users__component--widget__Edit--block__header {
    display: flex;
    align-items: center;
}

.system--users__component--widget__Edit--block__header--name {
    font-weight: 600;
    font-size: 22px;
    text-wrap: nowrap;
}

.system--users__component--widget__Edit--block__role {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 5px 0px;
    border-radius: 6px;
    border: 2px solid #e0e7ea;
}

.system--users__component--widget__Edit--block__role--new {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 5px 0px;
}

.system--users__component--widget__Edit--block__role--text {
    width: 100%;
    margin-left: 10px;
}

.system--users__component--widget__add--header__input--error_message {
    color: red;
    margin: 0px;
}

.system--users__component--widget__Edit--block__width {
    text-align: right;
    width: 100%;
}

.system--users__component--widget__Edit--block__right {
    display: flex;
    justify-content: right;
    width: 100%;
}

.system--users__component--widget__Edit--block__role--name__h {
    text-wrap: nowrap;
    min-width: 150px;
    twxt-size: 16px;
    font-weight: 500;
    color: rgba(117, 132, 138, 0.93);
}

.system--users__component--widget__Edit--block__status {
    display: flex;
    justify-content: right;
    width: 100%;
}

.system--users__component--widget__Edit--block__status--width {
    width: 150px;
}

.system--users__component--widget__button {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 20px;
}

.system--users__component--widget__Edit--block__style--action {
    min-width: 30px;
    heigth: 30px;
    margin-left: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add__edit--role__component__widget--button__show--more {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin-left: 10px;
    border: 1px solid #ffffff;
}

.add__edit--role__component__widget--button__show--more:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.16);
    border: 1px solid #149cce;
}

.system--users__component--widget__Edit--block__style--action:hover {
    cursor: pointer;
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.17);
}

.system--users__component--widget__Edit--block__style--action__aprove {
    min-width: 30px;
    heigth: 30px;
    margin-left: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.system--users__component--widget__Edit--block__style--action__aprove img {
    min-width: 20px;
    heigth: 20px;
}

.system--users__component--widget__Edit--block__style--action__aprove:hover {
    cursor: pointer;
    background-color: rgba(39, 206, 20, 0.16);
    border: 1px solid #6ece14;
}