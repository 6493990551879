.flex-row {
    display: flex;
    flex-direction: row;
}

.small-add-option-popup {
    position: absolute;
    background-color: var(--background-color-white);
    transform: translateX(-60px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.add-option-text {
    cursor: pointer;
    padding: 10px;
}

.add-option-text:hover {
    background-color: var(--hover-color-gray-blue);
}

.right-menu-subtask-item-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    gap: 16px;
}

.right-menu-subtask-item {
    color: var(--secondary-text-gray);
    font-weight: 400;
    word-break: break-all;
    text-decoration: none;
}

.right-menu-subtask-item:hover {
    cursor: pointer;
    text-decoration: underline;
}