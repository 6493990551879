.settings-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 235px;
    background-color: white;
    box-shadow: -3px -2px 4px 0px #75848a1f;
    border: 1px solid var(--border-border-primary, #e1e7ea);
    border-radius: 6px;
    padding: 8px;
}

.settings-popup-username {
    margin-left: 12px;
}

.settings-popup-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    margin: 12px 0px;
}

.settings-popup-btn .settings-img {
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/SettingCogGrey.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.settings-popup-btn:hover .settings-img {
    background-image: url("../../../assets/SettingCogBlue.svg");
}

.settings-popup-btn .change-password-img {
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/changePassword.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.settings-popup-btn:hover .change-password-img {
    background-image: url("../../../assets/changePasswordBlue.svg");
}

.settings-popup-btn .change-password-img {
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/changePassword.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.settings-popup-btn:hover .change-password-img {
    background-image: url("../../../assets/changePasswordBlue.svg");
}

.settings-popup-btn .feedback-img {
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/feedback.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.settings-popup-btn:hover .feedback-img {
    background-image: url("../../../assets/feedbackBlue.svg");
}

.settings-popup-btn p {
    margin: 0px 0px 0px 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--content-content-secondary, #75848a);
}

.settings-popup-version p {
    margin: 15px 0px 0px 35px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--content-content-secondary, #75848a);
}

.settings-popup-btn:hover p {
    color: var(--text-color-widgets-blue);
}
