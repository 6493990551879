.tag-display-field__size {
    height: 30px;
    margin: 2px;
}


.button_add:hover {
    background-color: #149cce69;
}

.tag-add-popup-tegs__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.tag-add-popup__show--all {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin: 2px;
    background-color: #75848a49;
    cursor: pointer;
}

.tag-add-popup__show--all:hover {
    cursor: pointer;
}

.tag-add-popup__hide__all {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #75848A;
    border-radius: 30px;
    margin: 2px;
    background-color: #75848a49;
}

.tag-add-popup__hide__all:hover {
    cursor: pointer;
}

.tegs_popup-items {
    display: flex;
    flex-wrap: wrap;
    max-width: 450px;
}


.tag-display-field__size:hover img {
    display: flex;
}

.tag-display-field__size:hover .tag-add-popup__del--teg {
    width: 20px;
}

.tag-add-popup__del--teg {
    margin-left: 5px;
    /*width: 0px;*/
    color: #4d595e49;
    border-radius: 50px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tag-add-popup__del--teg:hover {
    cursor: pointer;
    background-color: rgba(192, 192, 192, 0.45);
}

.tag-add-popup__del--teg img {
    display: none;
}

.task__teg-popup__add--teg {
    /*margin: 5px;*/
    /*height: 40px;*/
    width: 100%;
    padding-right: 5px;
}

.task__teg-popup__add--teg input {
    border-radius: 6px;
    width: 100%;
    border: 1px solid #75848a49;
    font-size: 14px;
    padding: 16px;
    height: 36px;

}

#new-teg-name-input:focus-visible {

    outline: 1px solid #149cce !important;
}

.task__teg--display__popup--add__teg--name {
    color: black;
    width: 100%;
    font-weight: 500;
}

.task__teg--display__popup--add__teg--cross {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 25px;
    height: 25px;
}

.task__teg--display__popup--add__teg--cross:hover {
    cursor: pointer;
    background-color: #75848a49;
}

.task__teg--display__popup--add__teg--new {
    border-top: 1px solid #75848a49;
    padding: 16px 16px 5px 16px;
    color: black;
    font-weight: 500;
}

.task__teg--display__popup--add__teg--new__add {
    margin: 10px 16px 16px 16px;
}

.task__teg--display__popup--add__teg--header {
    margin: 16px 16px 10px 16px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.task__teg--display__popup--add__teg {
    border-radius: 6px;
    bottom: 20px;
    left: -10px;
    min-height: 350px;
    position: absolute;
    background-color: white;
    z-index: 1021;
    width: 340px;
}

.widget__task--information__tegs--view__teg teg_form_on_task_page {
    top: 40px !important;
}

.tegs__color--popup__button--add__teg--block {
    width: 100%;
    display: flex;
    justify-content: right;
}

.task__teg--display__popup--add__teg--name__TagSelector {
    margin: 0px 10px 0px 10px;
}

.tegs__color--popup__button--add__save--block {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0px 16px 16px 16px;
}

.tegs__color--popup__button--add__teg {
    padding: 10px;
    background-color: #149CCE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    margin-top: 10px;
    width: 100px;
    color: white;
}

.tegs__color--popup__button--add__teg:hover {
    cursor: pointer;
    background-color: #149ccebb;
}

.tag-add-popup-button--block {
    zoom: 0.7;
}