.board-task-card {
    background: #fff;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    margin: 6px 4px 3px 12px;

    box-shadow: 0px 0px 5px rgba(117, 132, 138, 0.38);
}

.task-abbr-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*.task-abbr-container:hover {*/
/*    cursor: pointer;*/
/*    border-bottom: #149cce;*/
/*}*/

.drag-hidden {
    display: none;
}

.task__abbr__board {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.2px;
    color: #75848A;
}

.task__name__board a {
    font-weight: 600;
    font-size: 16px;
    width: 220px;
    text-overflow: ellipsis;
    cursor: default;
    position: relative;
    z-index: 0;
    word-break: break-word;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: #212121;
    text-decoration: none;
}

.task__name__board {
   display: flex;
   flex-direction: row;
}

.task__name__board:hover {
    cursor: pointer;
    border-bottom: #149cce;
}

.task__short--description__board {
    font-weight: 600;
    font-size: 16px;
    width: 220px;
    /* max-height: 50px;
    padding: 5px;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    cursor: default;
    position: relative;
    z-index: 0;
    word-break: break-word;
}

.task__short--description__button button {
    border: none;
    background-color: #ffffff00;
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.task__short--description__button button:hover {
    cursor: pointer;
    background-color: #149cce6b;
}

.task-name-container a {
    text-decoration: none;
    color: inherit;
    cursor: default;
}

.task-name-container a:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #149cce;
}

.task--description__board {
    /*padding: 5px;*/
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #75848a;
    width: 220px;
    max-height: 50px;
}

.dash__board--form-column__tasks__priority--list {
    display: flex;
    /*width: 95%;*/
}

.dash__board--form-column__tasks__priority--list__item {
    margin-right: 4px;
    /*margin-left: 10px;*/
}

.dash__board--form-column__tasks__menu {
    width: 95%;
    /*display: flex;*/
    /*flex-direction: row;*/
    flex-wrap: wrap;
    padding: 0px 16px 16px 16px;
    margin: 8px 4px 0px 0px;
}

.dash__board--form-column__tasks--elem__teg--list {
    display: flex;
    margin: 8px 4px 0px 0px;
}

.dash__board--form-column__tasks--elem__bottom {
    width: 100%;
    height: 56px;
}

.dash__board--form-column__tasks--elem__bottom--name {
    display: flex;
    align-items: center;
    width: 150px;
    /*border: 1px solid red;*/
}

.dash__board--form-column__tasks--elem__bottom--flex {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-top: 2px solid #c9caca6b;
    width: 100%;
}

.task-name-container input {
    border-radius: 6px;
}

.dash__board--form-column__tasks--elem__bottom--flex img {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    border: 1px solid rgba(117, 132, 138, 0.28);
}

.dash__board--form-column__tasks--elem__bottom--flex__name {
    white-space: nowrap;
    margin: 0px 10px 0px 10px;
    font-size: 14px;
    font-weight: 400;
    color: #75848a;
}

.dash__board--form-column__tasks--elem__bottom--flex__progress__bar {
    display: flex;
    justify-content: right;
    /*align-items: center;*/
    width: 100%;
    /*border: 1px solid red;*/
    /*margin: 10px;*/
}

.dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block {
    display: flex;
    align-items: center;
    width: 100px;
}

.dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block__header {
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 4px;
    color: #68727d;
}