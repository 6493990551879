.dash__board--form-column__tasks--elem__header {
    display: flex;
    justify-content: center;
    padding: 16px 16px 0px 16px;
}

.dash__board--form-column__tasks--elem__header__input {
    width: 28px;
    height: 28px;
    margin: 5px;
    padding: 5px;
}

.dash__board--form-column__tasks--elem__header__input input {
    width: 16px;
    height: 16px;
}

.dash__board--form-column__tasks--elem__header__shortDescription {
    width: 100%;
}

.dash__board--form-column__tasks--elem__header__shortDescription p {
    margin: 0px !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
}

/*.dash__board--form-column__tasks__menu {*/
/*    display: flex;*/
/*}*/

/*.dash__board--form-column__tasks__priority--list {*/
/*    width: 50%;*/
/*}*/

/*.dash__board--form-column__tasks--elem__teg--list {*/
/*    width: 50%;*/
/*}*/

.dash__board--form-column__tasks--elem__teg {
    display: flex;
    align-items: center;
    height: 30px;
    margin: 5px;
    border-radius: 25px;
    background: var(--background-color-tag-orange);
}

.dash__board--form-column__tasks--elem__priority__one {
    display: flex;
    align-items: center;
    width: 95%;
    height: 30px;
    border-radius: 25px;
    background: var(--background-color-priority-green);
}

.dash__board--form-column__tasks--elem__priority__two {
    display: flex;
    align-items: center;
    width: 95%;
    height: 30px;
    border-radius: 25px;
    background: var(--background-color-priority-yelow);
}

.dash__board--form-column__tasks--elem__priority__three {
    display: flex;
    align-items: center;
    width: 95%;
    height: 30px;
    border-radius: 25px;
    background: var(--background-color-priority-red);
}

.dash__board--form-column__tasks--elem__priority {
    margin: 5px;
}

.dash__board--form-column__tasks--elem__teg__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 25px;
    margin: 2px;
    background: var(--background-color-white);
}

.dash__board--form-column__tasks--elem__teg__img img {
    width: 24px;
    height: 24px;
}

.dash__board--form-column__tasks--elem__teg__text {
    margin: 5px;
    padding: 5px;
}

.board__container {
    width: 100%;
    height: calc(100vh - 80px);
}

.toolbar-container {
    position: sticky;
    z-index: 900;
    top: 80px;
    width: calc(100vw - 300px);

}

.board__container--columns {
    /*width: calc(100vw - 300px);*/
    min-height: calc(100vh - 135px);
    overflow: hidden;
}