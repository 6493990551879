.right-popup-wrapper {
    position: absolute;
    z-index: 1300;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0,0,0, 0.4);
}

.right-popup-wrapper.nonblocking {
    background-color: rgb(0,0,0, 0.0);
    pointer-events: none;
}

.right-popup-panel {
    height: 100%;
    /*width: 550px;*/
    border-radius: 8px 0 0 8px;
    background-color: var(--background-color-white);
    margin-left: auto;
    padding: 32px 24px 32px 32px;
}

.row-align {
    display: flex;
    flex-direction: row;
}

.right-popup-close:hover {
    background-color: var(--hover-color-gray-blue);
    border-radius: 1000px;

    cursor: pointer;
}

.right-popup-children-container {
    overflow-y: auto;
    scrollbar-gutter: stable;
    height: calc(100% - 64px);
    padding-right: 8px;
}

.right-popup-header {
    margin-bottom: 24px;
}