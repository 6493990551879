:root {
    --heightModal: 460px;
}

.add__edit--role__component {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: var(--heightModal);
    padding: 20px 20px 66px 20px;
    border-radius: 6px;
    height: 80%;
}

.add__edit--role__component--header {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    font-size: 24px;
    text-wrap: nowrap;
}

.add__edit--role__component--header__img {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
}

.add__edit--role__component--header__img img{
    padding: 10px;
    border-radius: 60px;
    width: 40px;
    height: 40px;
}

.add__edit--role__component--header__img img:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.29);
}

.add__edit--role__component--name__role {
    margin-top: 25px;
}

.add__edit--role__component--name__role--project__role {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    margin-bottom: 15px;
}

.add__edit--role__component--name__role--desc {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    height: 20px;
}

.add__edit--role__component--block__role--project__role--text  {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.add__edit--role__component--name__role--desc__chek {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 5px;
}

.add__edit--role__component--name__role--desc__chek input {
    width: 15px;
    height: 15px;
}

.add__edit--role__component--name__role--data__role {
    height: auto;
}

.add__edit--role__component--name__role--input input{
    border-radius: 6px;
    height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    padding-left: 10px;
    width: 100%;
}

.add__edit--role__component--name__group {
    border-radius: 6px;
    height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 5px 0px;
}

.add__edit--role__component--name__function {
    border-radius: 6px;
    min-height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 0px 10px 0px 10px;
    margin: 0px 0px 5px 30px;
}

.add__edit--role__component--name__function:hover {
    /*cursor: pointer;*/
    /*background-color: rgba(117, 132, 138, 0.2);*/
}

.add__edit--role__component--name__function input {
    width: 15px;
    height: 15px;
}

.add__edit--role__component--name__function--permissions__block {
    border: 2px solid rgba(117, 132, 138, 0.2);
    margin: 5px 0px 5px 70px;
    border-radius: 6px;
}

.add__edit--role__component--name__function--permissions__block--margin {
    height: 40px;
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding: 0px 10px 0px 10px;
}

.add__edit--role__component--name__function--permissions {
    border-radius: 6px;
    height: 40px;
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 5px 0px 5px 70px;
    padding: 0px 10px 0px 10px;
}

.add__edit--role__component--name__function--permissions input {
    width: 20px;
    heifht: 20px;
}

.add__edit--role__component--name__function--permissions__chek {
    width: 100%;
    display: flex;
    aling-items: center;
    justify-content: right;
}

.add__edit--role__component--name__function--chek {
    width: 100%;
    display: flex;
    aling-items: center;
    justify-content: right;
}

.add__edit--role__component--name__function--chek input {
    width: 15px;
    height: 15px;
}

.add__edit--role__component--name__function--block {
    min-width: 280px;
    text-wrap: wrap;
}

.add__edit--role__component--name__function--block__tooltip {
    position: relative;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    z-index: 10;
}

.add__edit--role__component--button__show--more {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin-right: 10px;
    margin-left: 5px;
    border: 1px solid #ffffff;
}

.add__edit--role__component--button__show--more:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.16);
    border: 1px solid #149cce;
}

.add__edit--role__component--name__role--scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.add__edit--role__component--block__role {
    width: 100%;
    /*height: auto;*/
    /*min-height: 60%;*/
    display: flex;
    flex-direction: column;
}

.add__edit--role__component--name__role--block {
    padding-right: 2px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: auto;
}

.add__edit--role__component--buttons {
    /*border: 1px solid red;*/
    position: absolute;
    z-index: 4000;
    bottom: 10%;
    right: calc(50% - var(--heightModal) / 2);
    width: var(--heightModal);
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
    /*display: none;*/
}

.add__edit--role__component--buttons__create {
    background-color: #149cce;
    color: white;
    border: none;
    width: auto;
    height: 36px;
    border-radius: 6px;
    padding: 0px 10px 0px 10px;
    font-size: 14px;
    font-weight: 500;
}

.add__edit--role__component--buttons__create:hover {
    background-color: rgba(20, 156, 206, 0.46);
    cursor: pointer;
}

.add__edit--role__component--buttons__cansel {
    background-color: rgba(20, 156, 206, 0.2);
    color: #149cce;
    border: none;
    width: auto;
    height: 36px;
    border-radius: 6px;
    margin-right: 10px;
    padding: 0px 10px 0px 10px;
    font-size: 14px;
    font-weight: 500;
}

.add__edit--role__component--buttons__cansel:hover {
    background-color: rgba(20, 156, 206, 0.29);
    cursor: pointer;
}
