.files_header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.files_header_rigth {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.files_header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.files_header_left_margin{
    margin-right: 15px;
}

.files-sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.files_body_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
}

.file_el {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 15px;
}

.file_el_left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.file_el_rigth {
    display: flex;
}

.file_download_wrapp {
    margin-right: 5px;
}

.file_button_all_files {
    padding: 0;
    border: none;
    font: inherit;
    color: var(--text-color-widgets-blue);
    background-color: transparent;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
    letter-spacing: 0.1em;
    text-decoration: underline;
}

.file_heigth{
    height: 226px;
}