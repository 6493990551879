.calendar--checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cover_project_wrapper {
  margin: 5px 0px 10px 0px;
  position: relative;
}

.cover_project_main_item {
  display: block;
  /* width: 486px; */
  height: 276px;
  border-radius: 6px;
  overflow: hidden;
}

.cover_project_default_images {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.cover_project_default_image {
  display: block;
  height: 93px;
  width: 154px;
  border-radius: 6px;
  overflow: hidden;
}

.cover_project_main_item img,
.cover_project_default_image img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  cursor: pointer;
}

.cover_project_wrapper .basic__tab--setting__elem--company__logo__button {
  position: absolute;
  top: 5%;
  right: 5%;
  width: fit-content;
}

.margin_top_editProj {
  margin-top: 15px;
}

.modal-window-container-full-screen {
  min-height: 850px;
}

.subproject {
  min-height: 400px;
}