.ui__component--tab__navigator {
    width: auto;
}

.ui__component--navigator {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--ui-color-paginator-border-bottom);
    width: fit-content;
}

.ui__component--navigator__tab {
    color: var(--ui-color-tab-text);
    padding: 2px 0px 14px 0px;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.ui__component--navigator__tab:hover {
    color: var(--ui-color-hover-tab-text);
    border-bottom: 1px solid var(--ui-color-hover-tab-border-bottom);
    cursor: pointer;
}

.ui__component--navigator__tab--selected {
    color: var(--ui-color-selected-tab-text);
    border-bottom: 1px solid var(--ui-color-selected-tab-border-bottom);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}