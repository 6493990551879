/* Chrome/Edge/Safari */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--background-color-scrollbar);
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--background-color-white);
}

/* Firefox */
/*body {*/
/*    scrollbar-width: thin;          !* "auto" или "thin"  *!*/
/*    scrollbar-color: blue orange;   !* плашка скролла и дорожка *!*/
/*}*/