.infoPanelWrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    width: fit-content;
    height: fit-content;
    padding: 3px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: var(--background-color-blue-teg);
    border-radius: 3px;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--text-color-form-white);

    
}