.task--chat__block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    min-width: var(--widjets-width-size-542-px);
    height: var(-moz-fit-content);
    height: -moz-fit-content;
    height: fit-content;
    /*min-height: 100%;*/
    /*width: 70%;*/
}