.board-menu-container {
  height: 100vh;
  width: 500px;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: top;
  padding: 20px;
}

.board-name-row__name {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;

}

.board-name-row__name input {
  height: 40px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 700;
}

.board-name-row__name h1 {
  height: 40px;
  border-radius: 6px;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 230px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
}

.board-menu-columns {
  background-color: white;
  /* padding: 30px 0px; */
  /* border: dotted; */
  border-radius: 6px;
}

.board-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1020;
  display: flex;
  justify-content: center;
  align-items: start;
}

.board-name-row {
  display: flex;
  justify-content: left;
  align-items: center;
}

.board-menu-overlay__button {
  background-color: rgba(255, 255, 255, 0);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  margin-left: 10px;
}

.board-menu-overlay__button img {
  width: 20px;
  height: 20px;
}

.board-menu-overlay__button:hover {
  cursor: pointer;
  background-color: #149cce6b;
}

.board-menu-overlay__button--crete {
  width: 100%;
}

.board-menu-overlay__button--crete button {
  width: 100%;
  background-color: #149cce;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.board-menu-overlay__button--crete button:hover {
  cursor: pointer;
  background-color: #149cce6b;
}