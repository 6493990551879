:root {
    --index: calc(1vw + 1vh);
}

body {
    margin: 0px !important;
    background: var(--background-color-body);
    font-family: 'Montserrat', sans-serif !important;
    /*font-family: 'Montserrat Variable', sans-serif;*/
}

html {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

.bacground__form {
    border-radius: 25px;
    background: var(--background-color-form) 80%;
}

.slider-thumb::before {
    position: absolute;
    content: "";
    /*left: 30%;*/
    /*top: 20%;*/
    width: 450px;
    height: 450px;
    background: #17141d;
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: sliderShape 5s linear infinite;
    display: block;
    z-index: -1;
    -webkit-animation: sliderShape 5s linear infinite;
}

@keyframes sliderShape {

    0%,
    100% {
        /*border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;*/
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }

    34% {
        /*border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;*/
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }

    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }

    67% {
        /*border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;*/
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}