.files-widget-container {
    max-height: 150px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    min-height: 144px;
    overflow-y: scroll;
    align-content: start;
}


.widget_wrapper--full__window .files-widget-container {
    max-height: calc(100% - 25px - 20px) !important;
    overflow-y: scroll;
}

.files-widget-file-card {
    min-width: 200px;
    /*max-width: 400px;*/
    width: 50%;
    padding: 5px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    /* margin-right: 10px; */
}

.files-widget-file_item_name{
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 0;
    
}
/* .files-widget-file_item_name:hover{
    display: flex;
    overflow:visible;
    cursor: pointer;
} */

.files-widget-file-card:hover {
    /*background-color: #EDF6F9;*/
}

.files-widget-file-name-text {

    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
    
    color: #149CCE;
}

.files-widget-file-type-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    letter-spacing: -0.01em;
    color: #75848A;
    
}

.files-widget-file-button-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 3px;
}

.files-widget-file-button-wrapper:hover {
    background-color: #EDF6F9;
}

.files-widget-checkbox {
    border: 1px solid #E1E7EA;
    box-shadow: 0px 1px 2px 0px #23273014;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0px 8px 0px 0px;
}

.ellipses {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    z-index: 0;  
}



