#right-control-panel {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 16px 32px 16px 32px;
}

.headName .p-breadcrumb {
    background: none;
    border: none;
    padding: 1rem;
}

.headName .p-menuitem-text {
    color: #75848a;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.headName a {
    color: #75848a;
    text-decoration: none;
}

.headName li:last-child > a > a {
    color: var(--text-color-form-black);
}

.headName nav .p-menuitem a {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.header-settings-popup-container {
    z-index: 4000;
    position: absolute;
    top: 70px;
    right: 30px;
}