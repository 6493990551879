:root {
    --background-color-form: rgba(255, 255, 255, 0.8);
    --background-color-white: rgb(255, 255, 255);
    --background-color-grey: #e3e3e3;
    --background-color-percentage: #D8D6D6;
    --background-color-scroolbar-track-grey: #9f9f9f;
    --background-color-blue: #149CCE;
    --background-color-blue-teg: #007CAA;
    --background-color-body: #EDF6F9;
    --background-color-scrollbar: #D6D6D6;
    --background-color-widget-light-gray: #F8FBFB;
    --background-color-widget-light-blue: #DFEFF4;
    --background-color-cancel:#D17266;
    --background-color-confirm: #DFEFF4;
    --opacity-blue-opacity-blue-4D: #149CCE0A;


    --text-color-form-black: #313131;
    --text-color-form-black-black: #12191C;
    --text-color-form-white: rgb(255, 255, 255);
    --text-color-form-lightgrey: rgb(204, 204, 204);
    --text-color-form-visited-pink: #CB50DE;
    --text-color-form-error-red: #BA4E41;
    --text-color-widgets-gray: #767676;
    --text-color-names-gray: #767676;
    --text-color-widgets-blue: #149CCE;
    --text-color-gray-light: #C5CFD3;

    --placeholder-color-form-grey: #ffffff;

    --border-color-bottom: #6041A6;
    --border-color-error: #a80000;
    --border-color-select: #E5E5E5;
    --border-color-popUp: #E5E5E5;

    --global-white-full: #ffffff;

    --button-color-orange: #B1464D;
    --button-color-pink: #661895;

    --box-shadow-grey: #444444;

    --border-widgets: #E5E5E5;

    --hover-color-blue: #d4edff;
    --hover-color-red: #F6E8DE;
    --hover-color-green: #ddf2d3;
    --hover-color-gray-blue: #EDF6F9;

    --menu-navigations-color-white: rgb(255, 255, 255);

    --widjet-nameProj-gray-on-table: #BABABA;

    --table-header-color: #75848A;

    --hover-popUp: #f8f8f8;

    --gray-button-background: #DFEFF4;
    --gray-button-background-hover: #cedade;
    --blue-button-background-hover: #107da2;

    --button-text-blue: #2BACDB;
    --button-text-green: #699F4D;
    --button-text-red: #BA4E41;

    --secondary-text-gray: #75848A;

    --border-gray: #E1E7EA;

}