.main-card-widget-header {
    display: flex;
    flex-direction: row;
}

.widget-search-icon-button {
    width: 36px;
    height: 36px;
    border: none;
    background-color: #fff;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 3px;
}
.widget-search-icon-button:hover {
    background-color: #EDF6F9;
}

.widget-search-input-field {
    width: 200px;
    height: 36px;
    border: none;
    background-color: #fff;
    outline: none;
    margin-right: 3px;
    border-radius: 6px;
}

.widget-search-input-field:focus {
    /*background-color: #EDF6F9;*/
}

.widget-filter-icon-button {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 3px;
}

.widget-filter-icon-button:hover {
    background-color: #EDF6F9;
}

.widget-cancel-icon-button {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 3px;
}

.widget-delete-icon-button {
    width: 36px;
    height: 36px;
    /*background-color: #fff;*/
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 3px;
}

.widget-delete-icon-button:hover {
    background-color: #EDF6F9;
}

.widget-add-icon-button {
    width: 36px;
    height: 36px;
    background-color: #149CCE;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.widget-cancel-icon-button:hover {
    background-color: #EDF6F9;
}

.widget-options-dots-icon-button{
    width: 36px;
    height: 36px;
    background-color: #DFEFF4;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.widget-filer-popup {
    position: absolute;
    width: 200px;
    height: fit-content;
    /*transform: translateX(-60%);*/
    z-index: 10;

    background: #ffffff;
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    cursor: auto;
}

.widget-filer-popup.tasks{
    transform: translateX(-60%);
}
.widget-filer-popup.team{
    transform: translate(-60%, 40%);
}

.widget-filter-popup-group {
    background-color: var(--background-color-widget-light-gray);
    padding: 8px;
}

.widget-filter-popup-variant {
    height: 36px;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
}

.widget-filter-popup-variant:hover {
    background-color: #EDF6F9;
}

.widget-filter-popup-variant > tr > td{
   vertical-align: middle;
}

.widget-filter-popup-variant > tr > td > p {
    margin: 7px;

}

.widget-filter-popup-variant > tr > td > input {
    width: 18px;
    height: 18px;
    margin: 7px;
    cursor: pointer;
}

.subtle-shadow-all-around {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.hidden {
    visibility: hidden;
}

.noHighlight {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.search-control-wrapper {
    margin-right: 2px;
    border: 1px solid #E1E7EA;
    border-radius: 6px;
}

/*Убирает крестик с серча*/
.widget-search-input-field[type="search"]::-webkit-search-cancel-button,
.widget-search-input-field[type="search"]::-webkit-search-decoration,
.widget-search-input-field[type="search"]::-webkit-search-results-button,
.widget-search-input-field[type="search"]::-webkit-search-results-decoration {
    display: none;
}