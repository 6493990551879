.user-monitor-page-main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    height: calc(100vh - 100px);
    /*Ширина окна - ширина левого меню - марджины и паддинги*/
    width: calc(100vw - 368px);
    margin: 32px;
    margin-top: 0px;
    /*border: red dotted 1px;*/
}

.user-monitor-top-toolbar {
    background-color: var(--background-color-white);
    height: fit-content;
    /*min-width: 100px;*/
    /*border: blue dotted 1px;*/
    border-radius: 6px;
    min-width: 100px;
}

.user-monitor-main-widget-area {
    /*border: green dotted 1px;*/
    margin-right: 10px;
    /*width: 100%;*/
    height: 100%;
    width: calc(100vw - 680px);
}

.user-monitor-right-bar-container {
    width: 330px;
    min-width: 330px;
    height: 100%;
    /*border: black dotted 1px;*/
    /*margin-left: auto;*/
    background-color: var(--background-color-white);
    border-radius: 6px;
    padding: 28px;

    position: relative;
    z-index: 1000;

    overflow: hidden;
    overflow-y: auto;
}

.user-monitor-widget-rows-display-part {
    padding: 28px 28px 20px 28px;
    background-color: var(--background-color-white);
    /* height: calc(100% - 68px); */
    /*border: hotpink dotted 1px;*/
    border-radius: 6px;
    margin-top: 10px;

    min-width: 100px;
    width: 100%;
}
.user-monitor-widget-rows-display-part-table-container {
    padding-right: 7px;
    padding-bottom: 20px;
    max-height: calc(100vh - 80px - 200px);
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;

    /*border: yellow dotted 1px;*/
}

.user-monitor-widget-rows-display-part table {
    border-collapse: collapse;
    table-layout: fixed;
}

.user-monitor-widget-rows-display-part table tr {
    width: 100%;
}

.sort-direction-changing-header.desc img {
    /*background-color: red;*/
    transform: rotate(180deg);
    /* Standard syntax */
}

.sort-direction-changing-header {
    user-select: none;
}
.monitor-table-link-task-name {
    display: block;
    overflow: hidden;
    word-break: break-word;
    white-space: pre-wrap;
    width: fit-content;
    max-width: 100%;
}

.user-monitor-widget-base-td {
    max-width: 200px;
    min-width: 100px;
}

.monitor-table-link,
.column-display-container .task-column-widget.nonselectable,
.user-monitor-widget-flexy-project-td {
    display: block;
    overflow: hidden;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 100%;
}

.sort-direction-changing-header.nameTask,
.sort-direction-changing-header.projectTask {
    width: 15%;
}

.name_colum.user-monitor-widget-flexy-task-name-td,
.user-monitor-widget-flexy-project-td {
    width: 100%;
}

.sort-direction-changing-header.columnTask {
    width: 10%;
}

.sort-direction-changing-header.columnTask:hover {
    width: 10%;
    background-color: transparent;
    cursor: default;
}

.sort-direction-changing-header.status {
    width: 7%;
}

.sort-direction-changing-header.priority {
    width: 9%;
}

.sort-direction-changing-header.type {
    width: 10%;
}

.sort-direction-changing-header.date {
    width: 7%;
}

.user-monitor-widget-rows-display-part thead tr {
    position: sticky;
    background-color: var(--background-color-white);
    top: 0;
    width: 100%;
}

.user-monitor-widget-rows-display-part th {
    /*//Тут прыгает при перестановке стрелочки*/
    padding: 10px;
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
}

.user-monitor-widget-rows-display-part tbody tr:hover {
    background-color: #0e9cd50a;
    cursor: pointer;
}

.user-monitor-widget-rows-display-part tbody tr.selected {
    background-color: #149cce1f;
}

.user-monitor-widget-rows-display-part .user-monitor-task-name {
    width: 100%;
}

.user-monitor-widget-rows-display-part td {
    border: none;
    margin: 0;
    width: fit-content;
    padding: 10px;
}
.user-monitor-widget-rows-display-part td.last {
    border-radius: 0px 6px 6px 0px;
}
.user-monitor-widget-rows-display-part td.first {
    border-radius: 6px 0px 0px 6px;
}

.card-shadow {
    box-shadow: 5px 4px 8px 0px #75848a1f;
}

.star-button-img:hover {
    box-shadow: 5px 4px 8px 0px #75848a1f;
}

.user-monitor-widget-flexy-task-name-td {
    width: 100%;
}
.user-monitor-right-menu-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px 5px 5px 0;
}

.user-monitor-right-menu-item a {
    margin-left: auto;
    font-weight: bold;
    color: var(--background-color-blue);
}
.user-monitor-right-menu-item a.bad {
    color: var(--text-color-form-error-red);
}

.right-bar-top-header {
    font-weight: bold;
    margin-bottom: 24px;
}

.user-monitor-right-bar-block-divider {
    background-color: var(--text-color-gray-light);
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.user-monitor-right-bar-block {
    height: fit-content;
}

.user-monitor-right-bar-block_last-comment {
    max-height: 30vh;
    min-height: 30vh;
    padding-right: 5px;
    overflow-y: scroll;
}

.user-monitor-right-bar-block_last-edit {
    max-height: 35vh;
    min-height: 35vh;
    padding-right: 5px;
    overflow-y: scroll;
}

.user-monitor-right-bar-container .exit-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    height: 24px;
    width: 24px;
    background-color: #dfeff4;

    border-radius: 1000px;

    position: absolute;
    /*container padding*/
    right: 16px;
    top: 16px;
}

.user-monitor-right-bar-container .exit-button:hover {
    background-color: #c3d2d6;
    cursor: pointer;
}

.user-monitor-right-bar-block .block-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #75848a;
}

.user-monitor-right-bar-block_block-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #75848a;
    margin-bottom: 5px;
}

.user-monitor-right-bar-block .block-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    color: #75848a;
    word-break: break-word;
    /*overflow: hidden;*/
}

.column-display-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    gap: 5px;
}

.column-display-container-slash {
    /*font-weight: bold;*/
    color: #75848a;
    align-self: center;
}

.last-comments-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    padding-top: 14px;
    gap: 4px;
}

.show-all {
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    text-decoration: underline;
    color: var(--text-color-widgets-blue);
}

.show-all:hover {
    cursor: pointer;
    color: #0f7296;
}

.last-comments-block .author {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
}

.last-comments-block .author .name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.last-comments-block .author .relation-to-task {
    background-color: #091e420f;
    padding: 3px;
    margin-left: auto;
    border-radius: 4px;

    font-family: Inter;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;

    color: #44546f;
}

.last-comments-block .date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #75848a;
}

.last-comments-block .comment {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #75848a;

    overflow: hidden;
}

.break-word {
    word-break: break-word;
    overflow-wrap: break-word;
}

.folded {
    max-height: 16vh;
    overflow: hidden;
}

.hidden {
}

.user-monitor-toolbar-button .filter_container {
    left: -250px;
}
