.custom_table--full__window {
    display: block;
    overflow: auto;
    width: 100%;
    height: calc(100% - 25px - 20px) !important;
    margin-top: 16px;
}

.custom_table--full__window th {

    background: rgb(255, 255, 255);
    color: var(--table-header-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 13px;
    min-width: 200px;
    border: none;
    text-align: start;
    padding: 5px 10px;

}

.custom_table--full__window td {

    text-align: start;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 5px 10px;
    color: var(--text-color-form-black);
    border: none;
    /*padding: 3px;*/

}

.custom_table {
    display: block;
    overflow: auto;
    width: 100%;
    height: 150px;
}

.custom_table table {
    width: 100%;
}

.custom_table thead {
    position: sticky;
    top: 0;
    z-index: 5;
}

.custom_table_thead {
    position: sticky;
    top: 0;
    z-index: 5;
}

.custom_table th {
    background: rgb(255, 255, 255);
    color: var(--table-header-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    border: none;
    text-align: start;
}

.custom_table td {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-color-form-black);
    border: none;
    padding: 3px;
}

