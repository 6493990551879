/*@media (prefers-color-scheme: light) {*/
:root {
    /* Темы навигатора вкладок */
    --ui-color-paginator-border-bottom: #E1E7EA;
    --ui-color-selected-tab-border-bottom: #2BACDB;
    --ui-color-selected-tab-text: #2BACDB;
    --ui-color-hover-tab-border-bottom: #2BACDB;
    --ui-color-hover-tab-text: #2BACDB;
    --ui-color-tab-text: #A9B7BC;

    /* Темы кнопки */
    --ui-color-hover-button: rgba(157, 157, 157, 0.25);
    --ui-color-a-button: #56666C;

    /* Темы абсолютного позиционирования */
    --ui-color-absolute-position: rgba(0, 0, 0, 0.5);
    --ui-color-absolute-position-button-confirm: rgb(20, 156, 206);
    --ui-color-absolute-position-button-cansel: rgb(223, 239, 244);
    --ui-color-absolute-position-button-cansel-text: #149CCE;
    --ui-color-absolute-position-button-confirm-text: rgb(223, 239, 244);
    --ui-color-absolute-position-head: #12191C;
    --ui-color-absolute-position-body: #56666C;
}
/*}*/