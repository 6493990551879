.checklist--body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    min-width: var(--widjets-width-size-542-px);
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    height: 250px;
    height: fit-content;

}

.checklist_button_add {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    background-color: var(--background-color-confirm);
    color: var(--text-color-widgets-blue);
    border: none;
    font-size: 14px;
    font-family: Inter var, sans-serif;
    height: 30px;
    margin-right: 10px;
    text-align: center;
    width: 50%;
    cursor: pointer;
    align-items: center;

}

.checklist_button_add--img {
    width: 15px;
    height: 15px;
    margin: 5px;
    margin-top: 8px;
}

.checklist_button_add--body {
    display: flex;
}

.task_checklist_group--input{
    /*border: solid;*/
    border-color: rgba(146, 146, 146, 0.493);
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
}


.task_checklist_elem--input {
    border: solid;
    border-color: rgba(146, 146, 146, 0.493);
    border-width: 1px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
    margin-left:50px;
    align-items:center;
}