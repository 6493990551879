.main-page-body {
    height: 100vh;
    display: flex;
    flex-direction: row;
}

#left-menu-wrapper {
    width: fit-content;
    background-color: #fff;
}

#right-part-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#top-menu-bar-wrapper {
    width: 100%;
}

#work-area-wrapper {
    width: 100%;
    height: 100%;
    /*overflow-y: auto;*/
}

#right-toolbar-popup {
    right: 0;
    position: absolute;
    min-width: 400px;
    width: fit-content;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
    visibility: hidden;
    border-radius: 12px 0 0 12px;
}

#right-toolbar-popup.active {
    visibility: visible;
}