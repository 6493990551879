.row-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.column-align {
    display: flex;
    flex-direction: column;
}

.subtask-control-base {
    width: 130px;
    min-width: 130px;
    border-radius: 8px;
    padding: 7px;
    height: fit-content;

    font-weight: 500;
    align-items: center;
}

.subtask-project-dropdown {
    max-width: 50%;
    min-width: 50%;
}

.subtask-control-base:hover {
    cursor: pointer;
}

.add-control {
    color: var(--button-text-blue);
}

.add-control:hover {
    background-color: var(--hover-color-gray-blue);
}

.remove-control {
    background-color: var(--hover-color-red);
    color: var(--button-text-red);
}

.added-control {
    color: var(--button-text-green);
}

.right-menu-subtask-select-items-container {
    gap: 16px;
}
.right-menu-subtask-select-select {
    height: 38px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
    width: 100%;

    border: 1px solid var(--border-gray);
}

.subtask-select-item {
    /*border: 1px solid black;*/
    align-items: center;
}

.subtask-select-item-info {
    width: 70%;
}

.subtask-select-item-info-name {
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
