.personal-settings-container-container {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100vh;
    width: 100vw;
}

.personal-settings-container {
    background-color: white;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 4000;
    height: 100vh;
    min-width: 550px;
    padding: 32px;
    border-radius: 10px 0px 0px 10px;
}

.settings-body-container {
    margin-top: 20px;
}

.personal-settings-image-selector {
    position: relative;
    top: 50px;
    right: 20px;
}

.header-text {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 20px;
}

.personal-settings-close-button:hover {
    background-color: transparent;
}

.personal-settings-close-button:focus {
    border: none;
    box-shadow: none;
}

.personal-settings-avatar-container {
    display: flex;
    justify-content: center;
}

.personal-settings-input-container {
    display: flex;
    flex-direction: column;
}

.personal-settings-input-container .input_label {
    margin: 10px 0px 5px 0px;
}

.personal-settings-input-container .error_message {
    margin: 0px;
}

.personal-settings-buttons {
    display: flex;
    justify-content: end;
}

.additional-settings-switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.personal-settings-container .modal-window-btns {
    justify-content: start;
}

.personal-settings-divider-text {
    font-weight: 500;
    color: var(--text-color-names-gray);
}