.container_pointer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 6px;
    border: 1px solid #e0e7ea;
    padding: 6px;
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    position: relative;
}

.dropdown_overview {
    border-radius: 6px;
    border: 1px solid #e0e7ea;
    padding: 5px;
    background: white;
    z-index: 2000;
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.div_with_error {
    border-color: red !important;
    border-width: 1px !important;
    animation: inputErrorAnimation 1s ease-in-out;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
}

.full-screen-overlay_dropdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: start;
}

.selected-option {
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dropdown_option {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;

    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.dropdown_option:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.4);
}
