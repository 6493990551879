/* Стилизация кнопки - добавление файла */
.input-file__task {
    position: relative;
    display: inline-block;
}
.input-file__task img{
    width: 15px;
    height: 15px;
}
.input-file__task-btn img{
    width: 30px;
    height: 30px;
}

.input-file__task-btn-grey img{
    width: 15px;
    height: 15px;
}

.input-file__task--size {
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px;
}

.input-file__task--size:hover {
    cursor: pointer;
}

.input-file__task input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: none;
    width: 0;
    height: 0;
}

/* Focus */
.input-file__task input[type=file]:focus + .input-file__task-btn {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}