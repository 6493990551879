.reset_button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    /* отображаем курсор в виде руки при наведении; некоторые
    считают, что необходимо оставлять стрелочный вид для кнопок */
    cursor: pointer;
}

.button__block-authorization {
    border: none;
    /* Убирает границу */
    appearance: none;
    /* Убирает стили браузера для элементов ввода */
    outline: none;
    /* Убирает контур фокуса */
    margin: 0;
    /* Убирает внешние отступы */
    padding: 0;
    /* Убирает внутренние отступы */
    background: none;
    /* Убирает фон */
    font: inherit;
    /* Наследует шрифт */
    line-height: normal;
    /* Устанавливает нормальную высоту строки */

    width: calc(var(--index) * 12);
    height: calc(var(--index) * 1.5);
    margin: 10px;
    color: var(--border-color-bottom);
    font-size: 18px;
    font-weight: bolder;
    background: var(--background-color-white);
    border-radius: 15px;
    border: 2px solid var(--border-color-bottom);
    transition: transform 0.3s;
}

.button__block-authorization:hover {
    border: none;
    /* Убирает границу */
    appearance: none;
    /* Убирает стили браузера для элементов ввода */
    outline: none;
    /* Убирает контур фокуса */
    margin: 0;
    /* Убирает внешние отступы */
    padding: 0;
    /* Убирает внутренние отступы */
    background: none;
    /* Убирает фон */
    font: inherit;
    /* Наследует шрифт */
    line-height: normal;
    /* Устанавливает нормальную высоту строки */

    cursor: pointer;
    width: calc(var(--index) * 12);
    height: calc(var(--index) * 1.5);
    margin: 10px;
    color: var(--border-color-bottom);
    font-size: 18px;
    font-weight: bolder;
    background: var(--background-color-grey);
    border-radius: 15px;
    border: 2px solid var(--border-color-bottom);
    /*transform: scale(1.02);*/
}

.project__form__button-add {
    width: 100%;
    display: flex;
    justify-content: right;
}

.project__form__button-add button {
    padding: 5px;
    margin: 5px;
    width: 250px;
    height: 36px;
    border-radius: 25px;
    background: var(--background-color-white);
    border: 2px solid var(--background-color-two);
    box-shadow: 1px 2px 10px var(--box-shadow-grey);
}

.project__form__button-add button:hover {
    cursor: pointer;
    background: var(--background-color-pink);
}

/* Новые глобальные стили для кнопок */
.button_reset_style {
    border: none;
    /* Убирает границу */
    appearance: none;
    /* Убирает стили браузера для элементов ввода */
    outline: none;
    /* Убирает контур фокуса */
    margin: 0;
    /* Убирает внешние отступы */
    padding: 0;
    /* Убирает внутренние отступы */
    background: none;
    /* Убирает фон */
    font: inherit;
    /* Наследует шрифт */
    line-height: normal;
    /* Устанавливает нормальную высоту строки */
}

.button_settings {
    margin: 0px !important;
}

.button_settings:hover {
    background-color: rgba(20, 156, 206, 0.35);
    border-radius: 50px;
    cursor: pointer;
}

.button_notification {
    margin: 0px !important;
}

.button_download {
    display: flex;
    width: var(--button-on-widgets-width);
    height: var(--button-on-widgets-height);
    cursor: pointer;
}

.button_more_gray {
    display: flex;
    width: var(--button-on-widgets-width);
    height: var(--button-on-widgets-height);
    cursor: pointer;
}

.button_more_blue {
    display: flex;
    width: var(--button-on-header-widgets-width);
    height: var(--button-on-header-widgets-height);
    cursor: pointer;
}

.button_add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--button-width-size-36-px);
    min-width: var(--button-width-size-36-px);
    height: var(--button-height-size-36-px);
    background-color: #149cce;
    border-radius: 5px;
    cursor: pointer;
}

.button_add img {
    width: 15px;
    height: 15px;
}

.btn_save_input_value {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: #d8f0cb;
    border: none;
    cursor: pointer;

}

.btn_save_input_value:focus-within {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfdbfe;
    border-color: #3b82f6;
}

.position_on_input {
    position: relative;
    left: -35px;
}

/* Стилизация кнопки - добавление файла */
.input-file {
    position: relative;
    display: inline-block;
}

.input-file img {
    width: 15px;
    height: 15px;
}

.input-file-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;

    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;

    width: var(--button-width-size-36-px);
    height: var(--button-height-size-36-px);
    background-color: #149cce;
    border-radius: 5px;
}

.input-file input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.input-file input[type="file"]:focus+.input-file-btn {
    box-shadow: 0 0 0 0.05rem rgba(0, 123, 255, 0.25);
}

/* Hover/active */
/* .input-file:hover .input-file-btn {
	background-color: #59be6e;
}
.input-file:active .input-file-btn {
	background-color: #2E703A;
} */

/* Disabled */
/* .input-file input[type=file]:disabled + .input-file-btn {
	background-color: #eee;
} */

.login_in_with_Google {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1.5px solid #8fc6f1;
    background-color: var(--background-color-white);
    color: black;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.login_in_with_Google img {
    margin: 0px 7px 0px 0px;
}

.login_in_with_Email {
    align-items: center;
    margin: 10px 0px;
}

.login_in_with_Email a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: black;
    border: transparent;
    color: #5c6375;
    width: 100%;
    padding: 10px;
    text-decoration: none;
}

.login_in_with_Email div {
    display: block;
    width: 167.5px;
    height: 1px;
    background-color: #e1e7ea;
}

.confirm_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 8px 8px;
    gap: 6px;

    width: 74px;
    height: 36px;

    background: var(--background-color-confirm);
    /* Shadow/XSM */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 6px;
    cursor: pointer;
}

.cancel_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 8px 8px;
    gap: 6px;

    width: 78px;
    height: 36px;

    background: var(--background-color-cancel);
    /* Shadow/XSM */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 6px;
    cursor: pointer;
}

.save_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 8px 8px;
    gap: 6px;

    width: fit-content;
    height: 36px;

    background: #149cce;
    /* Shadow/XSM */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 6px;
    cursor: pointer;
}

.login_btn {
    width: 100%;
}

.login_btn button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 8px 8px;
    gap: 6px;

    width: 100%;
    height: 48px;

    background: #149cce;
    /* Shadow/XSM */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
    border-radius: 6px;
    cursor: pointer;
}