.input--input__block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    min-width: var(--widjets-width-size-542-px);
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 4px 8px 0px #75848A1F;
    height: 250px;
    height: fit-content;
}

.input--input__block--buttons {
    max-height: 250px;
    width: 100%;
}

.input--input__block--buttons__send {
    display: flex;
    /*flex-direction: column;*/
    justify-content: right;
}

.input--input__block--buttons__send--div {
    width: 310px;
    display: flex;
    justify-content: right;
    position: relative;
    bottom: 50px;
    right: 25px;
    height: 0px;
}

.input--input__block--buttons__send--div__cansel {
    margin-right: 10px;
    background-color: #ffffff;
    border: 1px solid #149CCE;
    color: #149CCE;
    font-weight: bold;
    border-radius: 6px;
    width: 150px;
    height: 35px;
}

.input--input__block--buttons__send--div__cansel:hover {
    background-color: #e8ebf0;
    cursor: pointer;
}

.input--input__block--buttons__send--div__approve {
    /* position: relative; */
    width: 150px;
    height: 35px;
    /* bottom: 50px; */
    /* right: 25px; */
    background-color: #149CCE;
    border: none;
    color: #E1E7EA;
    font-weight: bold;
    border-radius: 6px;
}

.input--input__block--buttons__send--div__approve-block {
    /* position: relative; */
    width: 150px;
    height: 35px;
    /* bottom: 50px; */
    /* right: 25px; */
    background-color:rgba(117, 132, 138, 0.286);
    border: none;
    color: #E1E7EA;
    font-weight: bold;
    border-radius: 6px;
}


.input--input__block--buttons__send--div__approve:hover {
    background-color: #138fbd;
    cursor: pointer;
}

.input--chat__block {
    width: 100%;
    /* min-height: 50vh; */
}

.editor-toolbar {

}

.CodeMirror {
    max-height: 200px !important;
    min-height: 200px !important;

}

.EasyMDEContainer .CodeMirror {
    height: 100% !important;
    min-height: 100% !important;
}


.CodeMirror-sizer {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    border-right-width: 0px !important;
    min-height: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    max-height: 190px !important;
    min-height: 180px !important;
}

.CodeMirror-sizer div{
    max-height: 190px !important;
    /*min-height: 190px !important;*/
}

.CodeMirror-scroll {
    /*max-height: 250px !important;*/
    height: 100% !important;
    min-height: 100% !important;
    max-height: 190px !important;
    width: 100% !important;
    margin-right: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.CodeMirror-vscrollbar {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.CodeMirror-code {
    /*overflow-y: scroll;*/
}

.editor-statusbar {
    display: none !important;
}

.input--chat__block--messages__date {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    /*border: 2px solid red;*/
}

.input--chat__block--messages__date--num {
    margin-right: 10px;
    margin-left: 10px;
}

.input--chat__block--messages__date--line {
    width: 55%;
    background-color: #E1E7EA;
    min-height: 2px;
    max-height: 2px;
}

.input--chat__block--messages__message--author {
    font-weight: 600;
    margin-right: 10px;
}

.input--chat__block--messages__message--date {
    color: #75848A;
    margin-right: 10px;
}

.input--chat__block--messages__message--update {
    color: #75848A;
    margin-right: 10px;
}

.input--chat__block--messages__message--block__flex--left {
    word-break: break-word;
    display: flex;
    min-width: 60%;
    max-width: 90%;
    padding: 10px;
    margin-left: 25px;
}

.input--chat__block--messages__message--block__flex--right {
    word-break: break-word;
    display: flex;
    min-width: 90%;
    max-width: 90%;
    background-color: #DFEFF4;
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
}

.input--chat__block--messages__message--audio__chat--block {
    margin-top: 10px;
}

.input--chat__block--messages__message {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    justify-content: right;
}

.input--chat__block--messages__message--all {
    display: flex;
}

.input--chat__block--messages__message--block__right {
    display: flex;
    margin-top: 10px;
    justify-content: right;
}

.input--chat__block--messages__message--file__chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    min-height: 110px;
    padding: 10px;
    border-radius: 6px;
    background-color: white;
    font-size: 12px;
    margin-top: 10px;
}

.input--chat__block--messages__message--file__chat--block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
}

.input--chat__block--messages__message--file__chat img {
    width: 35px;
    height: 45px;
    /*margin-right: 10px;*/
}


.input--chat__block--messages__message--block__left {
    display: flex;
    margin-top: 10px;
    justify-content: left;
    width: 95%;
}

.input--chat__block--messages__message--img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8FBFB;
    border-radius: 30px;
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    overflow: hidden;
    border: 1px solid #E1E7EA;
    margin-right: 10px;
    margin-left: 10px;
}


.input--chat__block--messages__message--text h1 {
    font-size: 32px !important;
    font-weight: 700;
}

.input--chat__block--messages__message--text p {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
}

.input--chat__block--messages__message--text img {
    max-width: 550px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
}

.input--chat__block--messages__message--text pre {
    background-color: rgba(75, 85, 99, 0.16);
    padding: 8px;
    border-radius: 6px;
}

.input--chat__block--messages__message--text blockquote {
    font-style: italic;
    color: rgba(75, 85, 99, 0.68);
}

.code__block--chat__message {
    background-color: rgba(75, 85, 99, 0.16);
    border: 1px solid rgba(50, 57, 66, 0.16);
    border-radius: 4px;
    width: fit-content;
    padding: 10px
}

.editor-toolbar .separator {
    display: none !important;
}

.input--chat__block--messages__message--buttons button {
    background-color: rgba(255,255,255,0) ;
    border: none;
    color: #75848A;
    padding-block: 0px !important;
    padding-inline: 0px !important;
    margin-right: 10px;
}

.input--chat__block--messages__message--buttons button:hover {
    cursor: pointer;
    color: #149CCE;
}

.input--chat__block--messages__message--role {
    color: #091E42;
    background-color: rgba(0, 83, 113, 0.15);
    /*padding: 2px;*/
    font-weight: 600;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    margin-right: 10px;
}

