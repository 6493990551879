.widget-card-header-style {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #12191C;
    word-break: break-word;
    /*margin: 0 8px 8px 8px;*/
}

ul {
    margin: 0px !important;
    padding-inline-start: 0px !important;
}

.h1_60 {
    font-family: Inter;
    font-size: 60px;
    font-weight: 700;
    line-height: 68px;
}

.h3_36 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 42.96px;
}

.h1_28 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}

.h1_24 {
    font-size: 24px;
    font-weight: initial;
    color: var(--text-color-form-black);
}

.h1_24-black {
    font-size: 24px;
    font-weight: initial;
    color: var(--text-color-form-black);
}

.h2_20 {
    font-size: 20px;
    color: var(--text-color-form-black);
}

.h2_20-black {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color-form-black);
    margin: 0px;
}

.a_20-black {
    font-size: 20px;
    font-weight: 400;
    color: var(--text-color-form-black);
}

.p_18 {
    font-size: 18px;
    color: var(--text-color-form-black);
}

.p_18_error {
    font-size: 14px;
    color: var(--text-color-form-error-red);
}

.p_16 {
    font-size: 16px;
    color: var(--text-color-form-black);
}


.p_12 {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
}

.p_14 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.p_14_login {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.p-18 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.p_19 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.p_12_file_info_widgets {
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
    color: var(--text-color-widgets-blue);
}

.p_12_popUP {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-color-form-black);
}

.p_11_sub_header_widgets {
    margin: 0px;
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
    letter-spacing: 0.1em;
    color: var(--text-color-widgets-gray);
}

.p_10_file_info_widgets {
    margin: 0px;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    letter-spacing: -0.01em;
    color: var(--text-color-widgets-gray);
}

.a__18-menu {
    font-size: 20px;
    color: var(--text-color-form-black);
    border-bottom: 2px solid var(--text-color-form-white);
}

.a__18-menu__none-hover {
    font-size: 20px;
    color: var(--text-color-form-black);
}

.a__18-menu:hover {
    cursor: pointer;
    font-size: 20px;
    color: var(--text-color-form-visited-pink);
    border-bottom: 2px solid var(--text-color-form-visited-pink);
}

.a__18-podmenu {
    font-size: 14px;
    color: var(--text-color-form-lightgrey) !important;
}

.a__18-podmenu:hover {
    cursor: pointer;
    font-size: 16px;
    color: var(--text-color-form-visited-pink);
    /*border-bottom: 2px solid var(--text-color-form-visited-pink);*/
}

.a__18-black {
    font-size: 20px;
    color: var(--text-color-form-black);
}

.a__18-black__none-hover {
    font-size: 20px;
    color: var(--text-color-form-black);
}

.a__16-black__none-hover {
    font-size: 16px;
    color: var(--text-color-form-black);
}

.a__16-black:hover {
    cursor: pointer;
    font-size: 16px;
    color: var(--text-color-form-visited-pink);
    border-bottom: 2px solid var(--text-color-form-visited-pink);
}

.a__18-black:hover {
    cursor: pointer;
    font-size: 20px;
    color: var(--text-color-form-visited-pink);
    border-bottom: 2px solid var(--text-color-form-visited-pink);
}

.a_18-white__none-hover {
    outline: none;
    text-decoration: none;
    padding: 2px 1px 0;
}

.a_18 {
    outline: none;
    text-decoration: none;
    padding: 2px 1px 0;
    border-bottom: 2px solid var(--text-color-form-black);
}

.a_18:link {
    color: var(--text-color-form-black);
}

.a_18:visited {
    color: var(--text-color-form-visited-pink);
    border-bottom: 2px solid var(--text-color-form-visited-pink);
}

.a_18:focus {
    border-bottom: 1px solid;
}

.a_18:hover {
    border-bottom: 1px solid;
    cursor: pointer;
}

.a_18:active {
    color: #CD6234;
}

.a_header_name_16 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--text-color-names-gray);
}

.menue_14 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--text-color-names-gray);
}

.p-tabview-nav-link[aria-selected="true"] {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #5C6375;
}

.p-tabview-nav-link[aria-selected="false"] {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--widjet-nameProj-gray-on-table);
}

.table_p_14 {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-color-form-black);
}

.genInfo_body_item_p11 {
    font-size: 11px;
    font-weight: 700;
    line-height: 15.4px;
    letter-spacing: 0.1em;
    margin: 0px;
}

.p_gray {
    color: var(--text-color-widgets-gray);
}

.p_gray_light {
    color: var(--text-color-gray-light);
}

.p_blue {
    color: var(--text-color-widgets-blue);
}

.p_red {
    color: var(--text-color-form-error-red);
}

.p_black {
    color: var(--text-color-form-black);
}

.p_black_black {
    color: var(--text-color-form-black-black);
}

.p_white {
    color: var(--text-color-form-white);
}

.link_login {
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    text-decoration-line: underline;
    font-feature-settings: 'salt' on;

}

.p_12_login {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.pointer_custom {
    cursor: pointer;
}

.label_24 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}