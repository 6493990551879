.header-component {
    /*position: sticky;*/
    /*top: 0px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 300px);
    height: 80px;
    z-index: 1000;
    background: var(--background-color-body);
    /*border-bottom: 1px solid #E1E7EA;*/
    /*box-shadow: 5px 4px 8px 0px #75848A1F;*/
}

.header__component img {
    /*cursor: pointer;*/
    margin: 5px 20px;
}

.header__component img:hover {
    cursor: pointer;
    margin: 5px 20px;
    transform: scale(1.1);
}

.header__component-text a {
    width: 20px;
    margin: 10px 20px;
}

.header_icon_notification {
    margin-left: 5px;
    margin-right: 15px;
}
