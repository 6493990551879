.company-logo-name {
    margin: 10px;
    height: 80px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*padding: 10px;*/
}

.company-logo-name {
    cursor: pointer;
}

.company-logo-name img {
    max-width: 260px;
    height: 100%
    /*object-fit: cover;*/
    /*object-position: 50% 50%;*/
    /*border: 1px solid #e0e7ea;*/
}

.left-menu-item-container {
    margin: 12px;
}

.left-menu-item {
    height: fit-content;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    cursor: pointer;

    color: #75848a;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

/*.left-menu-item.unfolded > * > .unfold-arrow > svg {*/
/* .rotate180 {
    transform: rotate(180deg);
    transform-origin: center center;
}



.left-menu-item>*>tr>td {
    vertical-align: middle;
    height: 48px;
}

.left-menu-item-component .icon {
    padding: 10px 10px 10px 0;
} */

/*.left-menu-item:hover {*/
/*    background-color: #0E9CD50A;*/
/*}*/
/*.left-menu-item:hover:not(:has(div:hover)) {*/
/*    background-color: #0E9CD50A;*/
/*}*/

/* #messageCounter {
    background-color: #007CAA;
    height: 20px;
    width: fit-content;
    border-radius: 4px;
    padding: 2px 5px 2px 5px;

    color: white;
} */

/*.left-menu-item > .display-part {*/
/*    height: 48px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    !*justify-content: end;*!*/
/*}*/
/* 
.left-menu-item-component>.display-part:hover {
    background-color: #0E9CD50A;
}

.left-menu-item-component>.body-part {
    height: fit-content;
    width: 100%;
    border: blue dotted 1px;
    /*display: none;*/
/* }  */

.lvl1-project-item {
    height: 48px;
    padding: 10px 0 10px 0;
    border-radius: 8px;
}

.lvl1-project-item-display-part {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hidden {
    display: none;
}

.display-part {
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left-menu-item-component > .display-part > .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*background-color: red;*/
    /* margin-left: auto; */
}

.menuForm__component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuForm__component .p-tree-toggler {
    position: relative;
    left: 10px;
}

.menuForm__component .p-tree-container {
    overflow: hidden;
    position: relative;
    left: -15px;
    width: 270px;
}

.menuForm__component .p-tree {
    border: none;
}

.menuForm__component .p-treenode-content:hover {
    outline: 1px solid var(--background-color-blue);
    background-color: var(--background-color-white);
}

.menuForm__component .p-treenode-children .p-treenode-content:hover {
    color: var(--background-color-white);
    background-color: var(--background-color-blue);
}

.p-tree {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--text-color-form-black);
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler {
    color: var(--text-color-form-black);
    /* transform: rotate(90deg); */
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-children
    .p-treenode-content.p-highlight
    .p-tree-toggler {
    color: var(--background-color-white);
    /* transform: rotate(90deg); */
}

.menuForm__component .p-treenode-content {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
}

.menuForm__component .p-treenode-icon {
    order: 1;
    margin-right: -105px;
}

.p-tree .p-tree-container .p-treenode {
    padding-right: 0px;
    margin-top: 3px;
}

.menuForm__component .p-treenode-children li {
    padding-left: 30px;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-children
    .p-treenode-content.p-highlight {
    color: var(--background-color-white);
    background-color: var(--background-color-blue);
    border: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    color: var(--text-color-form-black);
    background-color: #f1f6f8;
    border: none;
}

.p-tree .p-treenode .p-treenode-label{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.panelForProject {
    position: relative;
    top: 60px;
    left: 210px;
    width: fit-content;
    z-index: 10000000 !important;
}

.wiki-link {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    padding-left: 30px;
    color: var(--blue-500, #007caa);
    text-decoration: underline;
    cursor: pointer;
}
