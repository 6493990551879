.subtasks-horizonal {
    height: 2px;
    background-color: #ccc;
    margin-right: 10px;
}

.subtasks-vertical {
    width: 2px;
    background-color: #ccc;
    margin-right: 10px;
    height: 100%;
}

.subtask-hide-button {
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
}

.subtask-subtasks-number-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #a9b7bc;
    margin-right: 4px;
}

.subtask-number-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 13.31px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2bacdb;
    text-decoration: none;
}

.widget_wrapper:has(.subtasks-table) {
    padding-left: 0px;
    padding-right: 0px;
}

.subtasks-table {
    border-collapse: collapse;
}

.subtasks-table th {
    text-align: left;
    color: #56666c;
}

.subtasks-row {
    min-height: 48px;
    height: 48px;
    border-bottom: 1px solid #e1e7ea;
}

.subtasks-row.highlighted {
    background-color: #149CCE14;
}

.subtask-name {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #56666c;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    max-width: 100%;
    width: fit-content;
}

.subtask-name:hover,
.subtask-board:hover {
    text-decoration: underline;
}

.subtask-board {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--text-color-form-black-black);
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    max-width: 100%;
}
