.popUpWrapper {
    position: relative;
    width: auto;
}
.popUpWrapper_inner {
    position: relative;
    top: 38px;
    left: -70px;
    z-index: 912;

}

.popUpLabel {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: var(--content-content-secondary, #75848A);
    padding: 10px;
}

.popUpContainer{
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-white);
    width: fit-content;
    height: fit-content;
    position: absolute;
    z-index: 910;
    border-radius: 6px;
    border: 1px solid var(--border-color-popUp);
    right: 10px;
    width: 200px;
    /* top: 15px; */
    filter: drop-shadow(-3px -2px 4px rgba(117, 132, 138, 0.12)) drop-shadow(5px 4px 8px rgba(117, 132, 138, 0.12));
    border-radius: 6px;

    /*z-index: 1025 !important;*/
}

.popUpItem:last-child>.p_12_popUP{
    color:  #D17266;
}

.popUpItem {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #E1E7EA;
    white-space: nowrap;
}

.popUpItem_inner {
    padding: 10px;
    cursor: pointer;
    /*width: 98px;*/
    /* border-bottom: 1px solid #E1E7EA; */
}

.popUpItem_inner:last-child{
    border-bottom: none;
}
.popUpItem:last-child{
    border-bottom: none;
}
.popUpItem_inner:hover {
    color: var(--background-color-blue);
    background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}

.popUpItem:hover {
    /*background-color: var(--hover-popUp);*/
        color: var(--background-color-blue) !important;
        background: linear-gradient(0deg, rgba(14, 156, 213, 0.04), rgba(14, 156, 213, 0.04)), #FFFFFF;
        box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.12);
}
.popUpItem:hover>.p_12_popUP{
    color: var(--background-color-blue);
}