:root {
    /*--*/
    --bacground-width-size-100-persent: 100%;
    --bacground-width-size-97-persent: 97%;
    --bacground-width-size-50-persent: 50%;
    --bacground-width-size-40-persent: 40%;
    --bacground-width-size-30-persent: 30%;
    --bacground-width-size-25-persent: 25%;

    /* Header */
    --header-heigth-size-80px: 80px;

    /* FORM */

    --border-radius-form-size-25-px: 25px;

    --margin-form-size-10-px: 10px;
    --margin-form-size-6-px: 6px;

    --padding-form-size-10-px: 10px;
    --padding-form-size-6-px: 6px;
    --padding-form-right-size-5-px: 5px;

    --height-form-size-26-px: 26px;

    --shadow-x-size-1-px: 1px;
    --shadow-y-size-2-px: 2px;
    --shadow-z-size-10-px: 10px;

    /* IMAGE */
    --image-width-size-26-px: 26px;
    --image-height-size-26-px: 26px;

    /* BUTTON */
    --button-width-size-26-px: 38px;
    --button-height-size-26-px: 38px;

    --button-width-size-36-px: 36px;
    --button-height-size-36-px: 36px;

    --button-width-size-20-px: 20px;
    --button-height-size-20-px: 20px;

    --button-on-widgets-width: 15px;
    --button-on-widgets-height: 15px;

    --button-on-header-widgets-width: 36px;
    --button-on-header-widgets-height: 36px;

    /* Widgets */
    --widgets-border-radius: 16px;
    --widjets-width-size-542-px:542px;
    --widjets-border-solid: 1px;


    /* Select */
    --select-border-solid: 1px;


}