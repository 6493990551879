h1 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 100%;
    font-weight: normal;
}

.projects_none_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}

.projects_none_container h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #313131;
}

.projects_main_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 87vh;
    flex-wrap: wrap;
    padding: 25px;
    overflow-y: auto;

}

.card_projects_container {
    display: flex;
    flex-direction: column;
    height: 518px;
    width: 368px;
    margin: 10px;
    border-radius: 6px;
    cursor: pointer;
    background: #FFFFFF;
    position: relative;
    overflow: hidden;
}

.card_projects_img {
    /* flex: none; */
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    opacity: 0.8;
    width: 368px;
    height: 276px;
    overflow: hidden;
}

.card_projects_img_wrap {
    transition: background-color 3s ease;
}


.card_projects_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;

    transition: height 0.5s ease;
}

.card_projects_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 28px;
    width: 368px;
    height: 242px;
    /* background: #FFFFFF; */
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
    z-index: 1;
    background-color: none;
    transition: background-color 3s ease;
    /* overflow: hidden; */
}

.card_projects_info img {
    margin-right: -10px !important;
}

.card_projects_progress {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.card_projects_code {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card_projects_footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.text_Card_name_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 312px;
    height: 47px;
    overflow: hidden;
}

.text_Card_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #12191C;

}

.text_Card_description_container{
    display: block;
    width: 312px;
    overflow: hidden;
    margin-bottom: 10px;
    overflow-y: auto;
}

.text_Card_description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #75848A;
}

.text_Card_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #C5CFD3;
}

.card_projects_progress_item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
}

.card_projects_footer .p-progressbar {
    background: var(--background-color-body);
    height: 8px;
}

.card_projects_footer .p-progressbar-label {
    display: none;
}

.card_projects_footer .p-progressbar-value {
    background: var(--background-color-blue);
}


.card_projects_container:hover .card_projects_img_wrap {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) !important;
}

.card_projects_container:hover .card_projects_info {
    background: linear-gradient(0deg, rgba(117, 132, 138, 0.04), rgba(117, 132, 138, 0.04)), #FFFFFF;
    /* background-color: linear-gradient(0deg, rgba(117, 132, 138, 0.04), rgba(117, 132, 138, 0.04)); */
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
}

.card_projects_container:hover .card_projects_img img {
    height: 110%;
}

.card_projects_boards_info {
    display: none;
}

.card_projects_container:hover .card_projects_boards_info {
    /* Frame 1321314252 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 12px;

    position: absolute;
    width: 141px;
    height: 40px;
    left: 28px;
    top: 18px;

    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #E1E7EA;
    border-radius: 100px;
    z-index: 20009;
}

.card_projects_container:hover .card_projects_dates_period {
    display: block;
    z-index: 10000;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 140%;

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;

    left: 200px;
    top: 30px;
}

.card_projects_container:active .card_projects_boards_info {
    display: none;
}

.card_projects_container:active .card_projects_dates_period {
    display: none;
}

.card_projects_boards_info_footer {
    display: none;
}

.card_projects_container:active .card_projects_footer .card_projects_progress {
    display: none;
}

.card_projects_container:active .card_projects_boards_info_footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 12px;

    z-index: 10009;
}

.card_projects_container .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card_projects_container .text_margin {
    margin-right: 5px;
}

.card_projects_users {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
    align-items: center;
}

.card_projects_dates_period {
    display: none;
}


.count_users {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 4px;
    width: auto;
    height: 24px;
    background: #E1E7EA;
    border-radius: 22px;
    margin-left: 10px;
}


.card_projects_container:active {
    border: 2px solid #149CCE;
    border-radius: 6px;
    z-index: 10006;
}

.card_projects_container:active .card_projects_img_wrap {
    opacity: 0.8;
}

.card_projects_container:active .card_projects_info {
    background: linear-gradient(0deg, rgba(20, 156, 206, 0.12), rgba(20, 156, 206, 0.12)), #FFFFFF;
    box-shadow: -3px -2px 4px rgba(117, 132, 138, 0.12), 5px 4px 8px rgba(117, 132, 138, 0.12);
    border-radius: 0px 0px 6px 6px;
}

.card_projects_container:active .card_projects_img img {
    height: 110%;
}

@media (min-width: 1870px) and (max-width: 1940px) {
    .projects_main_container {
        padding: 5px;
    }
}