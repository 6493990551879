.action-mode-button {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: solid 1px #E1E7EA;
    border-radius: 50px;

    cursor: pointer;
}

.action-mode-button.active {
    height: 30px;
    width: 30px;
    background-color: var(--text-color-widgets-blue);
    border: solid 2px #E1E7EA;
    outline: solid 1px var(--text-color-widgets-blue);
    margin: 1px;
}

.action-mode-button.active.empty {
    opacity: 0.4;
}

.action-mode-button.active:hover {
    box-shadow: 0 0 8px var(--text-color-widgets-blue);
}