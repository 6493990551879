.full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    /*text-align: center;*/
}

.full-screen-overlay__group {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: start;
    /*text-align: center;*/
}

.full-screen-overlay__board--width {
    height: 0px;
    position: sticky;
    z-index: 2000;
}

.full-screen-overlay__board {
    /*top: 0;*/
    /*left: 0;*/
    /*width: 100%;*/
    /*height: 100vh;*/
    /*!*background-color: rgba(0, 0, 0, 0.5);*!*/
    /*z-index: 1003;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: start;*/
    /*!*text-align: center;*!*/
}

.full-screen-overlay__board__column__options {
    /*background-color: rgba(0, 0, 0, 0.5);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 900;
    top: 0;
    left: 0;
}


.popup-form-background {
    background-color: white;
    /*padding: 28px;*/
    /*margin-top: 20px;*/
    width: fit-content;
    border-radius: 8px;
    max-height: 95vh;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*overflow-y: auto;*/
    /*overflow-x: hidden;*/
}

.name__column--style {
    font-weight: 600;
    font-size: 14px;
    height: 24px;
    display: flex;
    align-items: center;
    width: auto;
    white-space: nowrap;
    margin-right: 8px;
    width: 100%;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*cursor: pointer;*/
    /*position: relative;*/
    /*z-index: 0;*/
}
.name__column--ellipses {

}
.add__elem--task__button {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.add__elem--task__button img {
    width: 20px;
    height: 20px;
}

/*.dragbledfsdC {*/
/*    background-color: linear-gradient(bottom, #149cce28 0%, #149CCE 50%, #007bff 70%) !important;*/
/*    !* color:  #149CCE; *!*/
/*}*/

.add__elem--task__button:hover {
    cursor: pointer;
    background-color: #149cce6b;
}

.settings__column--popup__edit {
    /*border: 1px solid red;*/
}

.num__task--style {
    font-weight: 600;
    font-size: 14px;
    color: #149CCE;
    width: fit-content;
    color: #75848A;
    margin-right: 2px;
}

.canban-column-header {
    background-color: #149cce2d;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    border-radius: 18px;
    padding: 6px 16px 6px 16px;
    height: 36px;
    margin: 6px 12px 3px 12px;
}

.canban-column-header__options {
    display: flex;
    align-items: center;
}

.canban-column-header button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0);
}

.canban-column-header button:hover {
    cursor: pointer;
    background-color: #149cce6b;
}

.column-task-container {
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    height: 100%;
}

.column-settings-popup__position {
    position: absolute;
    z-index: 910;
    /*top: 5px;*/
    right: 240px;
    width: 0px;
    height: 0px;
}

.column-settings-popup {
    background-color: white;
    height: fit-content;
    width: 200px;
    padding: 10px;
    background-color: var(--background-color-white);
    border-radius: 6px;
    box-shadow: 5px 5px 3px 2px #75848a63;
    border: 2px solid #75848a2f;

}

.column-settings-popup__name {
    margin-bottom: 10px;
}

.column__popup--widget__settings--proxy__name__board input {
    min-height: 30px;
    font-size: 18px;
}

.column__popup--widget__settings--proxy {
    margin-top: 10px;
    border-top: 1px solid #75848a63;
}

.column__popup--widget__settings--proxy__add {
    display: flex;
    align-items: center;
    justify-content: center;
}

.column__popup--widget__settings--proxy__name__board {
    margin-top: 10px;
}

.column__popup--widget__settings--proxy button {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    background-color: #149CCE;
    color: white;
}

.column-settings-popup input {
    width: 100%;
    border: none;
    font-weight: 600;
}

.column-settings-popup__input--edit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-settings-popup__input--edit input:hover {
    /* border: 1px solid #75848a63; */
    cursor: pointer;
    color: #149CCE;
}

.column-settings-popup__block--color {
    border-top: 1px solid #75848a63;
    margin-top: 10px;
}

.column-settings-popup__block--color--name {
    margin-top: 10px;
}

.column-settings-popup__block--color--block {
    margin-top: 10px;
}

.create__preview--element__drag--block {
    padding: 16px;
}

.create__preview--element__drag--block__name {
    background-color: #75848a;
    width: 150px;
    height: 16px;
}

.dash__board--elem {
    position: relative;
    min-width: 320px;
    max-width: 320px;

    height: auto;
    display: flex;
    flex-direction: column;
    margin: 6px;
    overflow: hidden;
    /*flex: 1;*/
}

.canban-column-default {
    background-color: white;
    min-width: 320px;
    max-width: 320px;
    border-radius: 6px;
    padding: 2px 4px 6px 2px;
    border: 1px solid rgba(117, 132, 138, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /*background-color: white;*/
    /*border-radius: 8px;*/
    /*border: none;*/
}

.droppable-column {
    /*background-color: white;*/
    /*width: 300px;*/
    /*margin: 10px;*/
    /*gap: 10px;*/
}

.slider-hand {
    cursor: grab;
}