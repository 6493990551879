.basic__tab {
    height: 100%;
    padding: 2px;
}

.basic__tab--modal__window {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 6000;
    background: rgba(0, 0, 0, 0.34);
    display: flex;
    align-items: center;
    justify-content: center;
}

.basic__tab--modal__window--error__img {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
}

.basic__tab--modal__window--error__img--header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px
}

.basic__tab--modal__window--error__img--buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.basic__tab--modal__window--block__button--background {
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}

.basic__tab--modal__window--block__button--crop {
    width: 100px;
    height: 36px;
    border-radius: 6px;
    border: none;
    background-color: #149cce;
    color: white;
    font-weight: 600;
}

.basic__tab--modal__window--block__button--crop:hover {
    cursor: pointer;
    background-color: #82bed3;
}

.basic__tab--modal__window--block__button--close {
    width: 70px;
    height: 36px;
    border-radius: 6px;
    border: none;
    background: none;
    margin: 0px 10px 0px 10px;
    font-weight: 600;
    color: #149cce;
}

.basic__tab--modal__window--block__button--close:hover {
    border: 1px solid #149cce;
    cursor: pointer;
}

.basic__tab--modal__window--block__button {
    /*border: 1px solid red;*/
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.basic__tab--modal__window--block {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 80%;
    height: 80%;
    /*background-color: #fff;*/
    padding: 20px;
    /*border: 1px solid #e1e7ea;*/
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
}

.cropper-modal {
    padding: 16px;
}

.basic__tab--setting__elem {
    border-bottom: 2px solid #E1E7EA;
    display: flex;
    align-items: center;
    min-height: 100px;
    width: 100%;
}

.basic__tab--setting__elem--company__logo {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0px 10px 0px 0px;
    min-height: 100px;
}

.basic__tab--setting__elem--company__logo__button {
    position: relative;
    display: flex;
    align-items: end;
    right: 85px;
    width: 0px;
    /*border: red 1px solid;*/
}

.basic__tab--setting__elem--company__logo--button__cross {
    min-width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #149cce;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.basic__tab--setting__elem--company__logo--button__cross:hover {
    background-color: #dae4e7;
    cursor: pointer;
}

.basic__tab--setting__elem--company__logo--button__cross img {
    width: 16px;
    height: 16px;
}

.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
}

.img-preview {
    overflow: hidden;
}


.basic__tab--setting__elem--company__logo__button--file__Input {
    display: flex;
    align-items: center;
}

.basic__tab--setting__elem--company__logo__button input:hover {
    background-color: #1587b0;
    cursor: pointer;
    opacity: 0.3;
}

.basic__tab--setting__elem--name__company {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0px 10px 0px 0px;
    min-height: 100px;
}

.basic__tab--setting__elem--topic {
    width: 70%;
    display: flex;
    align-items: center;
}

.basic__tab--setting__elem--cover__block {

}

.basic__tab--setting__elem--cover__block--crop {
    border: 3px solid red;
}

.basic__tab--setting__elem--cover__block--crop .cropper-line {
    background-color: red;
    opacity: 0.8;
}

.basic__tab--setting__elem--cover__block--crop .cropper-point {
    background-color: red;
    height: 7px;
    width: 7px;
}

.basic__tab--setting__elem--cover__block--logo {
    width: 70%;
    display: flex;
    justify-content: left;
}

.basic__tab--setting__elem--topic h2 {
    font-weight: 400;
    font-size: 16px;
    /*border: 2px solid #E1E7EA;*/
    width: 100%;
    /*height: 48px;*/
    display: flex;
    align-items: center;
    color: #75848A;
}

.error {
    font-weight: 400;
    font-size: 16px;
    /*border: 2px solid #E1E7EA;*/
    border-radius: 6px;
    width: 70% !important;
    height: 48px;
    display: flex;
    text-align: center !important;
    color: #000000 !important;
    background-color: #fb6060;
}

.basic__tab--setting__elem--name__company input {
    height: 48px;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #E1E7EA;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.basic__tab--setting__elem--name__company input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #75848A;
}

.basic__tab--setting__elem--name__company input:focus-visible {
    outline: none !important;
    border: 2px solid #149cce;
}

.basic__tab--setting__elem--name__company textarea {
    margin: 20px 0px 20px 0px;
    padding: 10px;
    width: 100%;
    resize: none;
    height: 100px;
    border-radius: 6px;
    border: 2px solid #E1E7EA;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    font-family: 'Montserrat Variable', sans-serif;
}

.basic__tab--setting__elem--name__company textarea:focus {
    outline: none !important;
    border: 2px solid #149cce;
}

.basic__tab--setting__elem--name__company textarea::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #75848A;
}

.basic__tab--setting__elem--company__logo img {
    margin: 12px;
    padding: 10px;
    max-width: 400px;
    max-height: 100px;
    border: 1px solid #e0e7ea;
}

.basic__tab--setting__elem--cover__block {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.basic__tab--setting__elem--cover {
    height: 168px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
}

.basic__tab--setting__elem--cover img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border: 1px solid #e0e7ea;

}

.basic__tab--setting__elem--h2 {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #75848A;
    padding: 10px 0px 0px 10px;
}

.basic__tab--setting__elem--cover__button {
    position: relative;
    bottom: 30px;
    height: 0px;
    display: flex;
    justify-content: right;
}

.basic__tab--setting__elem--cover__button button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #149cce;
    border: none;
    border-radius: 50px;
}

.basic__tab--setting__elem--cover__button button:hover {
    background-color: #8ad1ea;
    cursor: pointer;
}

.basic__tab--setting__elem--input-file {
    position: relative;
    background-color: #149cce;
    border: 0.1px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basic__tab--setting__elem--input-file:hover {
    background-color: #8ad1ea;
    cursor: pointer;
}

.basic__tab--setting__elem--input-file-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;

    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;

    width: var(--button-width-size-36-px);
    height: var(--button-height-size-36-px);
    background-color: #149cce;
    border-radius: 5px;
}

.basic__tab--setting__elem--input-file input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.basic__tab--setting__elem--input-file input[type="file"]:focus + .basic__tab--setting__elem--input-file-btn {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.basic__tab--setting__elem--input-file__task--size {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50px;
}

.basic__tab--setting__elem--input-file__task--size:hover {
    cursor: pointer;
}

.basic__tab--setting__elem--buttons {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
}