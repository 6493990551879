.feedback_textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 5px;
  gap: 8px;
  width: 100%;
  height: 120px;
  background: #ffffff;
  border: 1px solid #8fc6f1;
  border-radius: 6px;
  outline: 0;
  margin-top: 5px !important;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;   
}