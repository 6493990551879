

.ui__component--button {
    padding-block: 0px;
    padding-inline: 0px;
    border: none;
    background-color: unset;
    padding: 2px;
    border-radius: 1.5px;
    display: flex;
    align-items: center;
}

.ui__component--button:hover {
    background-color: var(--ui-color-hover-button);
    cursor: pointer;
}

.ui__component--button__none--text {
    width: 24px;
    height: 24px;
}

.ui__component--button__text {
    width: fit-content;
    height: 24px;
}

.ui__component--button a {
    color: var(--ui-color-a-button)
    /*width: 20px;*/
    /*height: 20px;*/
}

.ui__component--button img {
    width: 20px;
    height: 20px;
}