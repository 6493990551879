.board-menu-column {
  border: solid;
  border-color: rgba(146, 146, 146, 0.493);
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 0px;
}

.dragDrop__edit {
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragDrop__edit:hover {
  cursor: pointer;
}

.board-menu-column__block {
  display: flex;
  align-items: center;
  width: 100%;
}

.dragDrop__edit img {
  width: 35px;
  height: 40px;
}

.drag-button {
  font-size: small;
}

.board-menu-column__block--name {
  /*width: 80%;*/
  width: 325px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
}

.board-menu-column__block--name input{
  width: 90%;
  height: 30px;
  border-radius: 6px;
  /* border: none; */
  border-color: rgba(146, 146, 146, 0.493);
}

.board-menu-column__block__button {
  display: flex;
  align-items: center;
}

.board-menu-column__block__button button {
  margin: 5px;
  background-color: rgba(240, 248, 255, 0);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 30px;
  height: 30px;
}

.board-menu-column__block__button button:hover {
  cursor: pointer;
  background-color: #149cce6b;
}

.drag-hidden {
  display: none;
}