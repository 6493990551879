.avatar{
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.xxl {
    display: block;
    width: 86px;
    height: 86px;  
    border-radius: 50%;
}

.xl {
    display: block;
    width: 56px;
    height: 56px;  
    border-radius: 50%;
}

.lg{
    display: block;
    width: 48px;
    height: 48px;  
    border-radius: 50%;
}

.m{
    display: block;
    width: 32px;
    height: 32px; 
    border-radius: 50%; 
}

.s{
    display: block;
    width: 24px;
    height: 24px;  
    border-radius: 50%;
}

.avatar_min{
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgb(212, 212, 212);
    margin-right: 3px;
}

.avatar_group_item {
    margin-left: 0px;
    border: 2px solid #FFF;
    border-radius: 50%;
}
