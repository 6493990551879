.project__edit--role__board--component {
    width: 100%;
    max-height: 80%;
    /*max-height: 80vh;*/
    /*min-height: 80vh;*/
    padding: 20px;
    background-color: white;
    border-radius: 6px;
}

.project__edit--role__board--component__in {
    max-height: 80%;
}

.project__edit--role__board--component__block {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 65vh;
    overflow-y: scroll;
    /*max-height: 65vh;*/
}

.project__edit--role__board--component__head {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-wrap: nowrap;
    padding: 10px 10px 10px 5px;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 400;
    font-size: 18px;
    color: #cfd5d9;
}

.project__edit--role__board--component__head:hover {
    background-color: rgba(117, 132, 138, 0.12);
    color: #149cce;
    cursor: pointer;
}

.project__edit--role__board--component__head--choise {
    color: #149cce;
    border-bottom: 2px solid #149cce
}

.project__edit--role__board--component__head--choise:hover {
    background-color: rgba(117, 132, 138, 0.12);
    color: #149cce;
    border-bottom: 2px solid #149cce;
    cursor: pointer;
}

.project__edit--role__board--component__tab {
    display: flex;
    align-items: center;
    box-shadow: inset 0 -2px 0 #e0e7ea;
    /*border-bottom: 2px solid #e0e7ea;*/
    margin-bottom: 20px;
}

.project__edit--role__board--component__edit {
    max-height: 70vh;
    min-height: 70vh;

}

.project__edit--role__board--component__role {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    font-size: 20px;
    text-wrap: nowrap;
    margin-bottom: 20px;
    color: rgba(117, 132, 138, 0.72);
}

.project__edit--role__board--access__permissions--flex {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 0px 10px;
}

.project__edit--role__board--access__permissions {
    border-radius: 6px;
    height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 5px 0px;
}

.project__edit--role__board--access__permissions--block {
    margin: 0px 0px 0px 40px;
}

.project__edit--role__board--access__permissions--block__item {
    border: 2px solid rgba(117, 132, 138, 0.2);
    border-radius: 6px;
    margin: 0px 10px 5px 40px;
}

.project__edit--role__board--arrow {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #ffffff;
}

.project__edit--role__board--arrow:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.16);
    border: 1px solid #149cce;
}

.project__edit--role__board--access__permissions--item__box {

}

.project__edit--role__board--access__permissions--item {
    height: 40px;
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding: 0px 10px 0px 10px;
}

.project__edit--role__board--access__permissions--input>input[type="checkbox"]:checked{
    border-radius: 6px;
}

.project__edit--role__board--access__permissions--input input {
    border-radius: 50px;
    width: 16px;
    height: 16px;
}

.project__edit--role__board--access__permissions--input__system--role>input[type="checkbox"]:checked::before {
    content: "\2715";
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 3px solid gray;
    border-radius: 2px;
    text-align: center;
    line-height: 16px;
    color: gray;
}

.project__edit--role__board--access__permissions--input__system--role>input[type="checkbox"]:checked::before {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    color: white;
    border: 3px solid gray;
    /*border-color: red;*/
}

.project__edit--role__board--access__permissions--input>input[type="checkbox"]:checked:hover {
    cursor: pointer;
}

.project__edit--role__board--access__permissions--input__margin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

/* .project__edit--role__board--access__permissions--input__system--role {
    border: 1px solid red;
} */

.project__edit--role__board--access__permissions--name__function {
    text-wrap: wrap;
    min-width: 350px;
}

.project__edit--role__board__buttons--exit button{
    background-color: rgba(14, 156, 213, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    color: #149CCE;
    font-weight: 600;
}

.project__edit--role__board__buttons--add button{
    background-color: #149CCE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: 600;
}

.project__edit--role__board__buttons--exit button:hover {
    background-color: rgba(14, 156, 213, 0.13);
    cursor: pointer;
}

.project__edit--role__board__buttons--add button:hover {
    background-color: rgba(20, 156, 206, 0.67);
    cursor: pointer;
}

.project__edit--role__board__buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px 0px;
}

.restrict__access--list__board {
    border-radius: 6px;
    height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 5px 0px;
}

.restrict__access--list__board--desc {
    width: 460px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
}

.restrict__access--list__board--chek {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.restrict__access--list__board--chek__size input{
    width: 16px;
    height: 16px;
}

.restrict__access--list__board--head {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    font-size: 20px;
    text-wrap: nowrap;
    margin-bottom: 20px;
    color: rgba(117, 132, 138, 0.72);
}

.restrict__access--list__column {
    border-radius: 6px;
    height: 40px;
    border: 2px solid rgba(117, 132, 138, 0.2);
    color: rgba(117, 132, 138, 0.72);
    display: flex;
    align-items: center;

    text-wrap: nowrap;
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 5px 90px;
}

.restrict__access--list__column--name {
    /*width: 100%;*/
    width: 460px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
}