.image__selector--modal__window {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 6000;
  background: rgba(0, 0, 0, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image__selector--modal__window--block {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 80%;
  height: 80%;
  /*background-color: #fff;*/
  padding: 20px;
  /*border: 1px solid #e1e7ea;*/
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
}

.image__selector--setting__elem--cover__block--crop {
  border: 3px solid red;
}

.image__selector--setting__elem--cover__block--crop .cropper-line {
  background-color: red;
  opacity: 0.8;
}

.image__selector--setting__elem--cover__block--crop .cropper-point {
  background-color: red;
  height: 7px;
  width: 7px;
}

.image__selector--modal__window--block__button--background {
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  height: fit-content;
}

.image__selector--modal__window--block__button--close {
  width: 70px;
  height: 36px;
  border-radius: 6px;
  border: none;
  background: none;
  margin: 0px 10px 0px 10px;
  font-weight: 600;
  color: #149cce;
}

.image__selector--modal__window--block__button--close:hover {
  border: 1px solid #149cce;
  cursor: pointer;
}

.image__selector--modal__window--block__button--crop {
  width: 100px;
  height: 36px;
  border-radius: 6px;
  border: none;
  background-color: #149cce;
  color: white;
  font-weight: 600;
}

.image__selector--modal__window--block__button--crop:hover {
  cursor: pointer;
  background-color: #82bed3;
}

.image__selector--setting__elem--company__logo__button {
  position: relative;
  display: flex;
  align-items: end;
  width: 0px;
  /*border: red 1px solid;*/
}

.image__selector--setting__elem--company__logo__button input:hover {
  background-color: #1587b0;
  cursor: pointer;
  opacity: 0.3;
}

.image__selector--setting__elem--company__logo__button--file__Input {
  display: flex;
  align-items: center;
}

.image__selector--setting__elem--input-file {
  position: relative;
  background-color: #149cce;
  border: 0.1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image__selector--setting__elem--input-file:hover {
  background-color: #8ad1ea;
  cursor: pointer;
}

.image__selector--setting__elem--input-file-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;

  width: var(--button-width-size-36-px);
  height: var(--button-height-size-36-px);
  background-color: #149cce;
  border-radius: 5px;
}

.image__selector--setting__elem--input-file input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.image__selector--setting__elem--input-file input[type="file"]:focus+.image__selector--setting__elem--input-file-btn {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.image__selector--setting__elem--input-file__task--size {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50px;
}

.image__selector--setting__elem--input-file__task--size:hover {
  cursor: pointer;
}

.image__selector--setting__elem--input-file__task--size {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50px;
}

.image__selector--setting__elem--input-file__task--size:hover {
  cursor: pointer;
}