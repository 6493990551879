.input_form {
    /* .input-placeholder */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 100%;
    height: 48px;

    background: #ffffff;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    outline: 0;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
}

.textarea_input{
    height: 100px !important;
    resize: none;
}

.input_form_login {
    width: 100%;
}
.input_form_login .p-icon-field {
    width: 100%;
}
.input_form_login .p-password {
    width: 100%;
}

.input_form_login input {
    /* .input-placeholder */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 100%;
    height: 48px;

    background: #ffffff;
    border: 1px solid #8fc6f1;
    border-radius: 6px;
    outline: 0;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
}

.input_form_surname {
    margin: 0px 5px 0px 0px;
}

.input_form_first_name {
    margin: 0px 5px;
}

.input_form_middle_name {
    margin: 0px 0px 0px 5px;
}

.input_form:focus {
    border: 2px solid #59bbec;
}

.input_form::placeholder {
    color: #c5cfd3;
}

.input_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.error_message {
    color: red;
    margin: -10px 0px 5px 0px;
    align-self: flex-start;
    font-family: Inter;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
}

@keyframes inputErrorAnimation {
    0% {
      box-shadow: 0 0 1px red;
    }
    50% {
      box-shadow: 0 0 2px red;
    }
    100% {
      box-shadow: 0 0 1px red;
    }
  }
  
.input_with_error input,
.textarea_with_error,
.div_with_error {
    border-color: red !important;
    border-width: 1px !important;
    animation: inputErrorAnimation 1s ease-in-out;
}


/* Стили для календаря */
.p-datepicker {
    font-weight: 500;
}

.p-datepicker span.p-highlight{
    justify-content: center;
    border-radius: 8px;
    color: var(--background-color-blue);
}
