.user-monitor-toolbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* overflow-x: auto; */
    scrollbar-width: none;
    justify-content: space-between;
}

.user-monitor-toolbar-right-part {
    /*border: black dotted 1px;*/
    height: 100%;
    width: fit-content;
    margin-top: 7px;
    margin-right: 7px;
    margin-left: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    gap: 5px;
}

.user-monitor-toolbar-left-part {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.user-monitor-toolbar-switch-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 7px;
    align-items: center;
    justify-content: center;
}

.show-completed-input-switch .p-inputswitch-slider {
    background: var(--background-color-blue);
    min-width: 48px;
}

.user-monitor-toolbar-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    height: 36px;
    width: 36px;
    text-align: center;
    /*border: 1px solid var(--background-color-blue);*/
    border-radius: 6px;
}

.user-monitor-toolbar-button:hover {
    background-color: var(--hover-color-gray-blue);
    cursor: pointer;
}


