.history-table-markdown-container {
  max-height: 198px;
  width: fit-content;
  overflow: hidden;
}

.history-table-hide-button {
  cursor: pointer;
  color: var(--text-color-widgets-blue);
}
.history-table-markdown-img {
}

.history-table-markdown-img div img {
  width: 10vw;
}