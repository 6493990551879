.create__column--block {

}

.create__column--block--name input {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 2px solid #149cce;
}

.create__column--block--name {
    width: 100%;
    height: 40px;
    margin-top: 5px;
}

.create__column--block--color {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.create__column--block--color__name {
    margin-right: 10px;
}

.create__column--block--status--name {
    margin-right: 10px;
}

.create__column--block--status select {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #149cce;
}

.create__column--block--status {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.create__column--block--proxy {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.create__column--block--proxy input{
    width: 20px;
    height: 20px;
}

.create__column--block--proxy select{
    height: 40px;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #149cce;
    font-size: 18px;
}

.create__column--block--proxy__input input{
    height: 40px;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #149cce;
    font-size: 18px;
}

.create__column--block--button {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: right;
}

.create__column--block--button button {
    height: 40px;
    width: 30%;
    border-radius: 6px;
    border: none;
    color: white;
    /* font-size: 18px; */
    background-color: #149cce;
}

.create__column--block--button button:hover {
    cursor: pointer;
    background-color: #149cce4d;
}

/* .create__column--block .color__picker--block {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.create__column--block .color__picker--block {
    display: flex;
} */