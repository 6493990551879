.exit-button {
    width: fit-content;
}

.exit-button:hover {
    background-color: transparent;
}

.exit-button-img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    background-image: url("../../../assets/exitButton.svg");
    background-size: contain;
}

.exit-button p {
    margin: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--content-content-secondary, #75848a);
}

.exit-button:hover p {
    color: var(--text-color-widgets-blue);
}

.exit-button:hover .exit-button-img {
    background-image: url("../../../assets/exitButtonBlue.svg");
}
