/* ToastNotification.css */
.toasts-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10000;
}

.toast {
    background-color: var(--text-color-form-black, #313131);
    color: var(--black-000, #f5f5f5);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 32px 80px 0px #292d321f;
    display: flex;
    justify-content: space-between;
    width: 300px;
    transform: translateX(100%);
    animation: slide-in 0.15s forwards ease-out;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.toast-body {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
}

.toast-text {
    margin: 0px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
}

.toast-cancel-button {
    background: none;
    border: none;
    cursor: pointer;
}

/* Стили для возможных дочерних компонентов */

.toast a {
    color: var(--content-content-teritary, #c5cfd3);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}
