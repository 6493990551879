.safety__settings {
    width: 100%;
    margin-top: 20px;
    height: 70vh;
}

.safety__settings--pod {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    /*justify-content: center;*/
}

.safety__settings--header__settings {
    height: 30px;
    /*border: 1px solid red;*/
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #75848A;
    text-align: left;
}

.safety__settings--choose--role {
    margin-left: 10px;
    padding-left: 10px;
    min-width: 270px;
    max-width: 350px;
    /*border: 2px solid rgba(117, 132, 138, 0.38);*/
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #050505;
    height: 30px;
}

.safety__settings--choose--role__current--arrow {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    margin-right: 10px;
    margin-left: 5px;
    border: 1px solid #ffffff;
}

.safety__settings--choose--role__current--arrow:hover {
    cursor: pointer;
    background-color: rgba(20, 156, 206, 0.16);
    border: 1px solid #149cce;
}

.safety__settings--choose--role:hover {
    /*cursor: pointer;*/
    /*background-color: rgba(208, 208, 208, 0.26);*/
}

.safety__settings--choose--role img {
    width: 28px;
    height: 28px;
}

.safety__settings--choose__modal {
    background-color: #fff;
    min-width: 220px;
    padding: 10px;
    border: 1px solid #e1e7ea;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(117, 132, 138, 0.38);
    position: relative;
    top: 15px;
    right: 260px;
    width: 250px;
    z-index: 2001;
}

.safety__settings--choose__modal--size {
    width: 0px;
    height: 0px;
}

.safety__settings--choose__modal--elem {
    text-align: right;
    padding: 5px;
    color: #75848A;
}

.safety__settings--choose__modal--elem:hover {
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(20, 156, 206, 0.15);
}

.safety__settings--choose--role__curent {
    width: 100%;
}

.safety__settings--choose__modal--leave {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

