.btnFilter {
    height: 36px;
    width: 36px;

    border-radius: 6px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btnFilterWrapper {
    display: flex;
    position: relative;
    height: 36px;
    width: 36px;
}

.btnFilterWrapper .label_counter {
    display: flex;
    align-items: center;
    justify-content:center;

    border-radius: 50%;
    background-color: var(--background-color-blue);
    width: 13px;
    height: 13px;


    font-size: 8px;
    font-weight: 800;
    color: var(--background-color-white);

    position: absolute;
    left: 20px;
    top: 2px;

}

.btnFilter:hover {
    background-color: var(--hover-color-gray-blue);
}
