.comment img {
    width: 230px;
}

.user-monitor-right-bar-block-history-head {
    display: flex;
    align-items: center;
}

.user-monitor-right-bar-block-history-relation-to-task {
    background-color: #091e420f;
    padding: 3px;
    margin-left: auto;
    border-radius: 4px;

    font-family: Inter;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;

    color: #44546f;
}

.user-monitor-right-bar-block-history_name {
    /*font-weight: 600;*/
    /*font-size: 16px;*/
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 10px 5px 5px 0px;
}

.user-monitor-right-bar-block-history_data {
    font-size: 14px !important;
}

.user-monitor-right-bar-block-history img{
    margin-right: 8px;
}

.user-monitor-right-bar-block-history_data-block>#taskType {
    margin-left: 0px !important;
}

.user-monitor-right-bar-block-history_data-block>#status {
    margin-left: 0px !important;
}

.user-monitor-right-bar-block-history_data-block>#priority {
    margin-left: 0px !important;
}

.user-monitor-right-bar-block-history_date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #75848a;
    margin-bottom: 15px;
}

.user-monitor-right-bar-block-hide h2{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #75848a;
}

#last-comment-foldable-field>.input--chat__block--messages__message--file__chat--block {
    margin: 5px;
}

#last-comment-foldable-field>.input--chat__block--messages__message--file__chat--block>.input--chat__block--messages__message--file__chat {
    border: 1px solid rgba(117, 132, 138, 0.24);
    /*min-width: auto !important;*/
}

#last-comment-foldable-field>.input--chat__block--messages__message--file__chat--block>.input--chat__block--messages__message--file__chat img{
    max-width: 40px;
}



/* .user-monitor-right-bar-block-history span{
    font-weight: 400;
} */