.global-loader-container-none_height {
    width: 0px;
    height: 0px;
}

.global-loader-container {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(23, 20, 29, 0.31);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-color-form-black);
    z-index: 9999;
}

.global-loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: var(--text-color-form-black) #0000;
    animation: globalLoader 1s infinite;
}

@keyframes globalLoader {
    to {
        transform: rotate(.5turn)
    }
}