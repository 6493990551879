.board-fast-filters-container {
  display: flex;
}

.board-filter-container {
  position: relative;
  z-index: 100;
}

.board-filter-container button {
  background-color: white;
  color: #75848A;
  border: 1px solid rgba(117, 132, 138, 0.2);
  padding: 10px;
  margin: 10px;
  border-radius: 30px;
}

.board-filter-container button:hover {
  cursor: pointer;
  background-color: #75848a18;
}

.board-filter-container__priority {
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-filter-container__priority a {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
}

.board-filter-container__priority img {
  margin-right: 10px;
  margin-left: 10px;
}

.board-filter-container__type {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-filter-container__type img {
  margin-right: 10px;
  margin-left: 10px;
}

.board-filter-dropdown__tag>.custom_search {
  width: 90%;
}

.board-filter-dropdown__tag--search button{
  width: 26px;
  height: 26px;
  padding: 0px !important;
  margin: 5px !important;
  border: none;
}

.board-filter-dropdown__tag--search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.board-filter-dropdown__tag--search__block--teg {
  height: 200px;
  overflow-y: scroll;
}

.board-filter-dropdown__tag {
  position: absolute;
  top: 50px;
  left: 10px;
  width: 240px;
  height: fit-content;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 5px 4px 8px 4px #75848A1F;
  border: 1px solid rgba(117, 132, 138, 0.2);
  border-width: 1px;
}

.board-filter-dropdown {
  position: absolute;
  top: 60px;
  left: -10px;
  width: 164px;
  height: fit-content;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 5px 4px 8px 4px #75848A1F;
  border: 1px solid rgba(117, 132, 138, 0.2);
  border-width: 1px;
}

.board-filter-dropdown__item {
  padding: 5px;
  margin: 5px;
  border-radius: 6px;
}

.board-filter-dropdown__item:hover {
  cursor: pointer;
  color: #149CCE;
}

.board-filter-dropdown__item--del {
  padding: 5px;
  border-radius: 6px;
  color: rgba(156, 156, 156, 0.671);
}

.board-filter-dropdown__item--del:hover {
  cursor: pointer;
}

.selected__board-filter-dropdown__item:hover {
  color: black;
  border: 1px solid #149CCE;
}

.selected__board-filter-dropdown__item {
  color: black;
  border: 1px solid #149CCE;
  /*background-color: #149CCE !important;*/
}

.fast-filters-popup-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh
}