
.main_page_widget > tr > th {
    border-style: none;
    background-color: white;
    margin: 0px;
    letter-spacing: 0.1em;
    color: var(--text-color-widgets-gray);

    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
}

.main_page_widget > tr > td {
    text-align: left;
    border-style: none;
    padding: 8px 8px;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.main_page_widget .main_header {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: -0.02em;
    text-align: left;

}