.roles--system__component--header {
    padding: 10px 0px 10px 0px;
    font-weight: 600;
    color: rgba(117, 132, 138, 0.904);
    display: flex;
    align-items: center;
    flex-direction: row;
}

.roles--system__component--widget__add--edit {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.295);
}

.roles--system__component--header button img{
    width: 15px;
    height: 15px;
}

.roles--system__component--header button{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
}


.roles--system__component--header button:hover{
    cursor: pointer;
    background-color: #149cce60;
    border: none;
}

.roles--system__component--roles__curent--role {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    border: 1px solid transparent;
    padding: 10px;
}

.roles--system__component--roles__curent--role__cheked {
    background-color: #149cce31;
    border: 1px solid #149cce;
}

.roles--system__component--roles__curent--role:hover {
    cursor: pointer;
    background-color: #149cce31;
    border: 1px solid #149cce;
}

.roles--system__component--roles__curent--role__input {
    margin-right: 5px;
}

.roles--system__component__buttons--block__size--hiden {
    display: flex;
    align-items: center;
    justify-content: right;
    flex-direction: row;
}

.roles--system__component__buttons--block {
    display: flex;
    align-items: center;
    justify-content: right;
    flex-wrap: nowrap;
    height: 0px;
    position: fixed;
    top: 130px;
    right: 50px;
    z-index: 1000;
    width: 100%;
}

.roles--system__component__buttons--block__size {
    display: flex;
    align-items: center;
    justify-content: right;
    flex-wrap: nowrap;
}

.roles--system__component__buttons--block__edit {
    background: none;
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 6px;
}

.roles--system__component__buttons--block__edit:hover {
    cursor: pointer;
    background-color: #149cce21;
}

.right-arrow {
    transform: rotate(90deg);
}