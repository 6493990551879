.arrow-button {
  height: 24px;
}

.arrow-button:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

.arrow-button:hover {
  background-color: transparent;
  cursor: pointer;
}